import { memoize } from '@/lib/helpers';
import { PLACES_PATH, ORGANIZATIONS_PATH } from '@/constants/endpoints';
import { getJson } from '../request';

export const getPlaces = memoize(() => {
  return getJson(PLACES_PATH);
});

export const getOrganizations = memoize(() => {
  return getJson(ORGANIZATIONS_PATH);
});

export const getPlacesByLevel = memoize((level) => {
  return getJson(`${PLACES_PATH}/${level}`);
});
