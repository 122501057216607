<template>
  <div v-if="visible" class="notification fade-in">
    <div class="notification-header">
      <SuccessIcon v-if="type === 'success'" class="icon" />
      <ErrorIcon v-if="type === 'error'" class="icon" />

      <span class="precontent">
        {{ precontent }}
      </span>
    </div>

    {{ content }}

    <RemoveIcon class="remove" @click="$emit('remove')" />
  </div>
</template>

<script>
import { SuccessIcon, RemoveIcon, ErrorIcon } from '@/components/Icons';

export default {
  components: {
    SuccessIcon,
    RemoveIcon,
    ErrorIcon,
  },
  props: {
    precontent: {
      type: String,
      default: '',
    },
    content: {
      type: String,
      default: 'Success',
    },
    visible: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'success',
    },
  },
  emits: [
    'remove',
  ],
};
</script>

<style scoped lang="scss" src="../../styles/components/Notification.scss" />
