import get from 'lodash/get';
import i18n from '@/plugins/i18n';
import { createSegment, getSegments, updateSegment, deleteSegment, unsubscribe, getSegment, updateSegmentPermissions } from '@/services/api/segment';
import { OWNER } from '@/constants/permissions';

const $t = i18n.t.bind(i18n);
const FILTER_PREDEFINED = 'predefined';
const FILTER_OWNER = 'owner';
const FILTER_SHARED = 'shared';
const FILTER_GLOBAL = 'global';

const state = {
  isLoading: false,
  segments: [],
};

const mutations = {
  SET_SEGMENTS_LOADING(state, isLoading) {
    state.isLoading = isLoading;
  },
  SET_SEGMENTS(state, segments) {
    state.segments = segments;
  },
};

const actions = {
  async fetchSegments({ commit }) {
    commit('SET_SEGMENTS_LOADING', true);
    const segments = await getSegments();
    commit('SET_SEGMENTS', segments);
    commit('SET_SEGMENTS_LOADING', false);
  },
  async fetchSegment({ dispatch }, { id, accept }) {
    const segment = await getSegment(id, accept);
    dispatch('fetchSegments');
    return segment;
  },
  async createSegment({ dispatch }, data) {
    const segment = await createSegment(data);
    dispatch('fetchSegments');
    return segment;
  },
  async copySegment({ dispatch, state }, { id: sourceSegmentId, name, description }) {
    const segment = state.segments.find(({ id }) => id === sourceSegmentId);
    await dispatch('createSegment', {
      name,
      description,
      filters: { ...segment.filters },
    });
  },
  async updateSegment({ dispatch }, { id, ...data }) {
    const segment = await updateSegment(id, data);
    await dispatch('fetchSegments');
    return segment;
  },
  async updateSegmentPermissions({ dispatch, getters }, { id, is, permissions }) {
    const segment = await updateSegmentPermissions({
      id,
      is,
      permissions,
      template: getters.template,
    });
    dispatch('fetchSegments');
    return segment;
  },
  async deleteSegment({ dispatch }, id) {
    await deleteSegment(id);
    await dispatch('fetchSegments');
  },
  async unsubscribeSegment({ dispatch }, id) {
    await unsubscribe(id);
    await dispatch('fetchSegments');
  },
};
const getters = {
  getSegments: ({ segments }, { currentUserId }) => (filter) => {
    return segments
      .filter((segment) => {
        const permissions = get(segment, 'permissions', []);
        if (filter === FILTER_OWNER) {
          const user = permissions.find((permission) => get(permission, 'user.id') === currentUserId);
          return get(user, 'permission') === OWNER;
        }
        if (filter === FILTER_SHARED) {
          return permissions.length > 1;
        }
        if (filter === FILTER_PREDEFINED) {
          return !segment.permissions;
        }
        if (filter === FILTER_GLOBAL) {
          return get(segment, 'is.global', false) || !segment.permissions;
        }
        return true;
      });
  },
  getSegmentsTranslatedTextValue: ({ segments }) => (additionalSegments = []) => {
    const allSegments = [
      ...segments,
      ...additionalSegments.filter((additionalSegment) => {
        return !segments.find((segment) => segment.id === additionalSegment.id);
      }),
    ];

    return allSegments.map((segment) => ({
      ...segment,
      text: $t(`segments.${segment.id}`) !== `segments.${segment.id}` ? $t(`segments.${segment.id}`) : segment.name,
      name: $t(`segments.${segment.id}`) !== `segments.${segment.id}` ? $t(`segments.${segment.id}`) : segment.name,
      description: $t(`segments.${segment.id}_description`) !== `segments.${segment.id}_description` ? $t(`segments.${segment.id}_description`) : segment.description,
      value: segment.id,
    })).sort((a, b) => {
      if (a.text.toLowerCase() > b.text.toLowerCase()) return 1;
      return -1;
    });
  },
  isSegmentsLoading: ({ isLoading }) => isLoading,
};

export default {
  state,
  getters,
  actions,
  mutations,
};
