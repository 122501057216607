<template>
  <svg width="140" height="112" viewBox="0 0 40 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M40 4C40 1.8 38.2 0 36 0H4C1.8 0 0 1.8 0 4V28C0 30.2 1.8 32 4 32H36C38.2 32 40 30.2 40 28V4ZM36 4L20 14L4 4H36ZM20 18L4 8V28H36V8L20 18Z"
      fill="#646b71"
    />
  </svg>
</template>
