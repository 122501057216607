export const METRIC_TOTAL_SALES = 'total_sales';
export const METRIC_TRANSACTIONS = 'transactions';
export const METRIC_CUSTOMERS = 'customers';
// "avg_sale" is more correct name, but to support legacy code we use "avg_sales" in some places
export const METRIC_AVG_SALE = 'avg_sale';
export const METRIC_AVG_SALES = 'avg_sales';
export const METRIC_AVG_SALES_BY_COUNTRY = 'avg_sales_by_country';
export const METRIC_AVG_SALES_BY_STATE = 'avg_sales_by_state';
export const METRIC_AVG_SALES_BY_CITY = 'avg_sales_by_city';
export const METRIC_INTERCHANGE_FEES = 'interchange_fees';
export const METRIC_COVID = 'covid';
export const MEDIAN_SALE = 'median_sale';
export const METRIC_PRODUCT_SALES = 'product_sales';
export const METRIC_PRODUCT_UNITS_SOLD = 'product_units_sold';
export const METRIC_POINTS_EARNED = 'loyalty_points_earned';
export const METRIC_POINTS_REDEEMED = 'loyalty_points_redeemed';

export const MAX_REPORT_SUMMARY_METRICS = 3;
export const PIE_CHART_METRICS_UNAVAILABLE = [METRIC_AVG_SALES_BY_COUNTRY, METRIC_AVG_SALES_BY_STATE, METRIC_AVG_SALES_BY_CITY, METRIC_COVID];
export const HEAT_MAP_CHART_METRICS_UNAVAILABLE = [METRIC_COVID];
export const REPORT_SUMMARY_METRICS = [METRIC_TOTAL_SALES, METRIC_TRANSACTIONS, METRIC_AVG_SALES, METRIC_CUSTOMERS];
export const NUMBER_METRICS = [METRIC_TRANSACTIONS, METRIC_CUSTOMERS, METRIC_COVID, METRIC_PRODUCT_UNITS_SOLD, METRIC_POINTS_EARNED, METRIC_POINTS_REDEEMED];

export const METRIC_TYPE_PERCENT = 'percent';
export const METRIC_TYPE_CURRENCY = 'currency';
export const METRIC_TYPE_NUMBER = 'number';
