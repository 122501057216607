import { getLandingReports } from '@/services/api/report';

const state = {
  types: [],
  columnMappingSchemas: [],
};

const mutations = {
  SET_TYPES_LINKS(state, types) {
    state.types = types;
  },
  SET_COLUMN_MAPPING_SCHEMAS(state, columnMappingSchemas) {
    state.columnMappingSchemas = columnMappingSchemas;
  },
};

const actions = {
  async loadReportsLinks({ commit, getters }) {
    const { reports, data, settings, templates } = await getLandingReports(getters.template, getters.getIndustry);
    commit('SET_TYPES_LINKS', reports);
    commit('SET_COLUMN_MAPPING_SCHEMAS', data);
    commit('SET_APP_SETTINGS', settings);
    commit('SET_TEMPLATE_DEFAULT_SETTINGS', templates);
  },
};

const getters = {
  getReportsTypes: ({ types }) => types,
  getColumnMappingSchemas: ({ columnMappingSchemas }) => columnMappingSchemas || [],
};

export default {
  state,
  getters,
  actions,
  mutations,
};
