<template>
  <svg
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.2519 4.21191C12.2519 1.88556 10.3664 0 8.03967 0C5.71298 0 3.82776 1.88556 3.82776 4.21191C3.82776 7.54492 8.03967 12.4767 8.03967 12.4767C8.03967 12.4767 12.2519 7.52777 12.2519 4.21191ZM5.96246 3.96041C5.96246 2.81287 6.8928 1.88287 8.04 1.88287C9.18721 1.88287 10.1175 2.81287 10.1175 3.96041C10.1175 5.10795 9.18721 6.03795 8.04 6.03795C6.8928 6.03795 5.96246 5.10795 5.96246 3.96041Z"
      fill="#000"
    />
    <path
      d="M8.03952 4.98188C8.60366 4.98188 9.06098 4.52455 9.06098 3.96042C9.06098 3.39629 8.60366 2.93896 8.03952 2.93896C7.47539 2.93896 7.01807 3.39629 7.01807 3.96042C7.01807 4.52455 7.47539 4.98188 8.03952 4.98188Z"
      fill="#000"
    />
    <path
      d="M10.421 11.0117C10.0777 11.5077 9.7556 11.9401 9.4873 12.2871C12.1489 12.4468 13.8932 13.0214 14.5421 13.4491C13.7819 13.9504 11.5228 14.6551 8.04018 14.6551C4.55755 14.6551 2.29844 13.95 1.53823 13.4491C2.18715 13.0217 3.93015 12.4474 6.58903 12.2874C6.32004 11.9404 5.9976 11.5084 5.65398 11.0124C2.3788 11.3349 0 12.3039 0 13.4487C0 14.8575 3.59997 16 8.04018 16C12.4807 16 16.08 14.8578 16.08 13.4487C16.0807 12.3032 13.6992 11.3339 10.421 11.0117Z"
      fill="#000"
    />
  </svg>
</template>
