<template>
  <svg
    width="14"
    height="13"
    viewBox="0 0 14 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 1.5L6.5 4.5L10.5 2L13.5 1"
      stroke="#000"
    />
    <path
      d="M0.5 6L6.5 7.5L10.5 5H13.5"
      stroke="#000"
    />
    <rect
      y="11"
      width="14"
      height="2"
      fill="#000"
    />
  </svg>
</template>
