import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faStar, faFile } from '@fortawesome/free-regular-svg-icons';
import {
  faTachometerAlt,
  faDatabase,
  faHeartbeat,
  faThLarge,
  faPlusCircle,
  faBookOpen,
  faCog,
  faBell,
  faQuestionCircle,
  faCheck,
  faSearch,
  faEllipsisH,
  faChevronLeft,
  faChevronRight,
  faChevronDown,
  faCalendarAlt,
  faTimes,
  faArrowDown,
  faExclamationCircle,
  faAngleDown,
  faCaretDown,
  faTimesCircle,
} from '@fortawesome/free-solid-svg-icons';

library.add(
  faTachometerAlt,
  faDatabase,
  faHeartbeat,
  faThLarge,
  faPlusCircle,
  faBookOpen,
  faCog,
  faBell,
  faQuestionCircle,
  faCheck,
  faSearch,
  faEllipsisH,
  faChevronLeft,
  faChevronRight,
  faChevronDown,
  faCalendarAlt,
  faTimes,
  faArrowDown,
  faExclamationCircle,
  faAngleDown,
  faCaretDown,
  faTimesCircle,
  faStar,
  faFile,
);

export default FontAwesomeIcon;
