const mdReportRoutes = [
  {
    path: '/graph',
    alias: '/compare',
    component: () => import('../views/CompareReport.vue'),
    meta: {
      name: 'custom-report',
    },
  },
  {
    path: '/graph/:sessionId',
    alias: '/compare/:sessionId',
    component: () => import('../views/CompareReport.vue'),
    meta: {
      name: 'custom-report',
    },
  },
  {
    path: '/graph/:sessionId/:group',
    alias: '/compare/:sessionId/:group',
    component: () => import('../views/CompareReport.vue'),
    meta: {
      name: 'custom-report',
    },
  },
  {
    path: '/graph/:sessionId/:group/:from',
    alias: '/compare/:sessionId/:group/:from',
    component: () => import('../views/CompareReport.vue'),
    meta: {
      name: 'custom-report',
    },
  },
  {
    path: '/graph/:sessionId/:group/:from/:to',
    alias: '/compare/:sessionId/:group/:from/:to',
    component: () => import('../views/CompareReport.vue'),
    meta: {
      name: 'custom-report',
    },
  },
];

export default mdReportRoutes;
