import get from 'lodash/get';
import i18next from '@/plugins/i18n';
import {
  abortAllMetrics,
  createMetric, deleteMetric,
  getMetrics,
  unsubscribeMetric,
  updateMetric,
} from '@/services/api/metrics';
import { OWNER } from '@/constants/permissions';
import {
  MEDIAN_SALE,
  METRIC_AVG_SALES,
  METRIC_AVG_SALES_BY_CITY,
  METRIC_AVG_SALES_BY_COUNTRY,
  METRIC_AVG_SALES_BY_STATE,
  METRIC_COVID,
  METRIC_CUSTOMERS,
  METRIC_INTERCHANGE_FEES,
  METRIC_POINTS_EARNED, METRIC_POINTS_REDEEMED,
  METRIC_PRODUCT_SALES,
  METRIC_PRODUCT_UNITS_SOLD,
  METRIC_TOTAL_SALES,
  METRIC_TRANSACTIONS,
  METRIC_TYPE_CURRENCY,
  METRIC_TYPE_NUMBER,
  NUMBER_METRICS,
} from '@/constants/metrics';

const $t = i18next.t.bind(i18next);

const state = {
  isLoading: false,
  metrics: [],
};

const mutations = {
  SET_METRICS_LOADING(state, isLoading) {
    state.isLoading = isLoading;
  },
  SET_METRICS(state, metrics) {
    state.metrics = metrics;
  },
};

const actions = {
  async fetchMetrics({ commit }) {
    commit('SET_METRICS_LOADING', true);
    abortAllMetrics();
    const { metrics } = await getMetrics();
    commit('SET_METRICS', metrics);
    commit('SET_METRICS_LOADING', false);
  },
  async createMetric({ dispatch }, data) {
    const metric = await createMetric(data);
    dispatch('fetchMetrics');
    return metric;
  },
  async updateMetric({ dispatch }, data) {
    const metric = await updateMetric(data);
    await dispatch('fetchMetrics');
    return metric;
  },
  async unsubscribeMetric({ dispatch }, id) {
    await unsubscribeMetric(id);
    await dispatch('fetchMetrics');
  },
  async deleteMetric({ dispatch }, id) {
    await deleteMetric(id);
    await dispatch('fetchMetrics');
  },
};

const getters = {
  getMetricsByFilter: ({ metrics }, { currentUserId }) => (filter) => {
    switch (filter) {
      case 'my':
        return metrics.filter((metric) => {
          const { user: owner } = metric.permissions.find(({ permission }) => permission === OWNER);
          return owner.id === currentUserId;
        });
      case 'shared':
        return metrics.filter((metric) => get(metric, 'permissions', []).length > 1);
      case 'global':
        return metrics.filter((metric) => get(metric, 'is.global') === true);
      default:
        return metrics;
    }
  },
  getDefaultMetrics: (state, { isProductAvailable, isLoyaltyAvailable }) => {
    const metrics = [
      METRIC_TOTAL_SALES,
      METRIC_TRANSACTIONS,
      METRIC_CUSTOMERS,
      METRIC_AVG_SALES,
      METRIC_AVG_SALES_BY_COUNTRY,
      METRIC_AVG_SALES_BY_STATE,
      METRIC_AVG_SALES_BY_CITY,
      MEDIAN_SALE,
      METRIC_INTERCHANGE_FEES,
      METRIC_COVID,
    ];
    if (isProductAvailable) {
      metrics.push(METRIC_PRODUCT_SALES, METRIC_PRODUCT_UNITS_SOLD);
    }
    if (isLoyaltyAvailable) {
      metrics.push(METRIC_POINTS_EARNED, METRIC_POINTS_REDEEMED);
    }
    return metrics;
  },
  getMetrics: ({ metrics }) => metrics.filter(({ formula }) => !!formula),
  getMetricById: ({ metrics }) => (metricId) => metrics.find(({ id }) => id === metricId),
  getMetricNameById: (store, { getMetricById }) => (metricId) => {
    if (i18next.exists(metricId)) {
      return $t(metricId);
    }
    if (getMetricById(metricId)) {
      return getMetricById(metricId).name;
    }
    return $t('locations.unknown');
  },
  getMetricFormatById: ({ metrics }) => (metricId) => {
    const customMetric = metrics.find(({ id }) => id === metricId);
    if (customMetric) {
      return customMetric.format;
    }
    if (NUMBER_METRICS.includes(metricId)) {
      return METRIC_TYPE_NUMBER;
    }
    return METRIC_TYPE_CURRENCY;
  },
  isMetricsLoading: ({ isLoading }) => isLoading,
};

export default {
  state,
  getters,
  actions,
  mutations,
};
