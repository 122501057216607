<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="8" y="8" width="13" height="13" rx="2" :stroke="color" stroke-width="2" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6 5H14.5455C15.0977 5 15.5455 5.44772 15.5455 6H17.5455C17.5455 4.34315 16.2023 3 14.5455 3H6C4.34315 3 3 4.34315 3 6V14.5455C3 16.2023 4.34315 17.5455 6 17.5455L6 15.5455C5.44771 15.5455 5 15.0977 5 14.5455V6C5 5.44772 5.44772 5 6 5Z"
      :fill="color"
    />
  </svg>
</template>

<script>
export default {
  props: {
    disabled: Boolean,
  },
  computed: {
    color() {
      return this.disabled ? '#8d8f93' : '#000';
    },
  },
};
</script>
