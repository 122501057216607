<template>
  <svg
    width="108"
    height="108"
    viewBox="0 0 108 108"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M27 17.5H26.5V18V90V90.5H27H81H81.5V90V40.5V40H81H59V18V17.5H58.5H27ZM18.5 18C18.5 13.352 22.352 9.5 27 9.5H62.7929L89.5 36.2071V90C89.5 94.648 85.648 98.5 81 98.5H27C22.352 98.5 18.5 94.648 18.5 90V18ZM44.5 80.5H36.5V54.5H44.5V80.5ZM58 80.5H50V63.5H58V80.5ZM71.5 80.5H63.5V72.5H71.5V80.5Z"
      fill="#A4ABB4"
      stroke="#A4ABB4"
    />
  </svg>
</template>
