<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M19.99 7.5C19.99 6.78 19.62 6.15 19.05 5.8L10 0.5L0.95 5.8C0.38 6.15 0 6.78 0 7.5V17.5C0 18.6 0.9 19.5 2 19.5H18C19.1 19.5 20 18.6 20 17.5L19.99 7.5ZM17.99 7.5V7.51L10 12.5L2 7.5L10 2.82L17.99 7.5ZM2 9.84V17.5H18L17.99 9.87L10 14.86L2 9.84Z" :fill="color" />
  </svg>
</template>

<script>
export default {
  props: {
    disabled: Boolean,
  },
  computed: {
    color() {
      return this.disabled ? '#8d8f93' : '#000';
    },
  },
};
</script>
