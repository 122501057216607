import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import intersection from 'lodash/intersection';
import clone from 'lodash/clone';
import without from 'lodash/without';
import i18next from '@/plugins/i18n';
import { memoize } from '@/lib/helpers';
import { getProducts } from '@/services/api/products';

const $t = i18next.t.bind(i18next);

class ProductService {
  constructor() {
    this.products = [];
    this.isInit = false;
    this.init = memoize(this.init.bind(this));
    this.unzipProducts = memoize(this.unzipProducts.bind(this));
    this.isCategory = this.isCategory.bind(this);
    this.isProduct = this.isProduct.bind(this);
  }

  /*
    Method must be executed before call any other helper functions
   */
  async init() {
    this.products = await getProducts();
    this.isInit = true;
  }

  /*
  Method returns array of all products
  @return { array } product skus
 */
  /* eslint-disable-next-line class-methods-use-this */
  getAllProducts() {
    return ['all'];
  }

  isCategory(value) {
    return this.products.categories.some(({ id }) => id === value);
  }

  isProduct(value) {
    return this.unzipProducts().some(({ sku }) => sku === value);
  }

  getOptions() {
    const categories = this.products.categories.map((category) => ({
      id: category.id,
      label: category.name,
      children: category.products.length ? category.products.map((product) => ({
        id: product.sku,
        label: product.name,
      })) : undefined,
    }));
    return [{
      id: 'all',
      label: $t('all_products'),
      children: categories.length ? categories : undefined,
    }];
  }

  /*
    Method takes products tree values and returns the flatten array with sku and name of store
    @param { array } organizations tree values
    @returns { array } list of all organizations in flatten array { sku: ..., name: ... }
  */
  unzipProducts() {
    return this.products.categories.reduce((products, category) => {
      return products.concat(category.products);
    }, []);
  }

  /*
    Method takes array of skus and returns names joined
    @param { array } array of skus
    @returns { string } formatted names of products
   */
  getLabel(value) {
    const products = this.unzipProducts();
    let labels = [];
    let restValue = clone(value);

    if (isEmpty(restValue) || isEqual(products.map(({ sku }) => sku).sort(), clone(value).sort())) {
      labels = [$t('all_products')];
    } else {
      this.products.categories.reduce((accLabels, category) => {
        const productsSkus = category.products.map(({ sku }) => sku);
        if (isEqual(intersection(productsSkus, restValue), productsSkus)) {
          restValue = without(restValue, ...productsSkus);
          accLabels.push(category.name);
        }
        return accLabels;
      }, labels);

      labels = labels.concat(restValue.map((item) => {
        if (this.isCategory(item)) {
          return this.products.categories.find(({ id }) => id === item).name;
        }
        const product = products.find(({ sku: productSku }) => item === productSku);
        return product ? product.name : item;
      }));
    }

    return labels.join(', ');
  }
}

export default new ProductService();
