import qs from 'qs';
import last from 'lodash/last';
import get from 'lodash/get';
import { deleteJson, getJson, postJson, putJson } from '@/services/request';
import { METRIC_PATH, METRIC_DATA_PATH } from '@/constants/endpoints';
import { METRIC_TYPE_PERCENT } from '@/constants/metrics';

const abortControllers = [];

export async function getMetrics(filter) {
  abortControllers.push(new AbortController());
  const params = qs.stringify({ filter }, { addQueryPrefix: true });
  return getJson(`${METRIC_PATH}${params}`, {
    signal: last(abortControllers).signal,
  });
}

export async function getMetric(id) {
  return getJson(`${METRIC_PATH}/${id}`);
}

export async function getMetricPreview(data, options) {
  const response = await postJson(`${METRIC_DATA_PATH}/preview`, data);
  const format = get(options, 'format');

  response.graph = response.graph.map((graph) => ({
    ...graph,
    value: format === METRIC_TYPE_PERCENT ? graph.value * 100 : graph.value,
  }));

  return response;
}

export async function getMetricSummaryPreview(data) {
  return postJson(`${METRIC_DATA_PATH}/preview/summary`, data);
}

export async function createMetric({ name, description, variables, formula, format, trend }) {
  return postJson(METRIC_PATH, { name, description, variables, formula, format, trend });
}

export async function updateMetric({ id, permissions, is, name, description, variables, formula, format, trend }) {
  return putJson(`${METRIC_PATH}/${id}`, { permissions, is, name, description, variables, formula, format, trend });
}

export async function deleteMetric(id) {
  return deleteJson(`${METRIC_PATH}/${id}`);
}

export async function unsubscribeMetric(id) {
  await deleteJson(`${METRIC_PATH}/${id}/unsubscribe`);
}

export const abortLatestMetrics = () => {
  if (abortControllers.length) {
    const abortController = abortControllers.pop();
    abortController.abort();
  }
};

export const abortAllMetrics = () => {
  while (abortControllers.length) {
    abortLatestMetrics();
  }
};
