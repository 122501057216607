import { createApp, h } from 'vue';
import mitt from 'mitt';
import vClickOutside from 'click-outside-vue3';
import BasicLayout from '@/layouts/BasicLayout.vue';
import router from '@/router';
import store from '@/store';
import FontAwesomeIcon from '@/plugins/font-awesome';
import { VueI18next } from '@/plugins/i18n';
import { intercomConfig, isIntercomEnabled } from '@/plugins/intercom';
import VCalendar, { calendarConfig } from '@/plugins/v-calenar';
import '@/plugins/validator';
import VueTippy, { tippyConfig } from '@/plugins/tippy';
import '@/plugins/moment';
import '@/styles/styles.scss';

const app = createApp({
  setup() {
    return () => h(BasicLayout);
  },
}).use(router)
  .use(store)
  .use(vClickOutside)
  .use(VueTippy, tippyConfig)
  .use(VCalendar, calendarConfig)
  .use(VueI18next)
  .component('font-awesome-icon', FontAwesomeIcon);

app.config.globalProperties.emitter = mitt();

if (isIntercomEnabled) {
  // eslint-disable-next-line
  (function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/' + intercomConfig.appId;var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s, x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();

  store.$intercom = window.Intercom;
}

app.mount('#app');
