const reportRoutes = [
  {
    path: '/report/:sessionId/:scope/:time',
    component: () => import('../views/ReportView.vue'),
    meta: {
      name: 'sales-report',
    },
  },
  {
    path: '/report/:sessionId/:scope/:time/:from',
    component: () => import('../views/ReportView.vue'),
    meta: {
      name: 'sales-report',
    },
  },
  {
    path: '/report/:sessionId/:scope/:time/:from/:to',
    component: () => import('../views/ReportView.vue'),
    meta: {
      name: 'sales-report',
    },
  },
  {
    path: '/reports/:scope/:entity/:time/:segment/:from/:to',
    component: () => import('../views/ReportView.vue'),
    name: 'reportFromTo',
    meta: {
      name: 'sales-report',
    },
  },
  {
    path: '/reports/:scope/:entity/:time/:segment/:from',
    component: () => import('../views/ReportView.vue'),
    name: 'reportFrom',
    meta: {
      name: 'sales-report',
    },
  },
  {
    path: '/reports/:scope/:entity/:time/:segment',
    name: 'report',
    component: () => import('../views/ReportView.vue'),
    meta: {
      name: 'sales-report',
    },
  },
  {
    path: '/reports/:scope/:entity/:time',
    name: 'generalReport',
    component: () => import('../views/ReportView.vue'),
    meta: {
      name: 'sales-report',
    },
  },
  {
    path: '/analysis/:templateId',
    component: () => import('../views/CustomReport.vue'),
    meta: {
      name: 'custom-report',
    },
  },
  {
    path: '/analysis/:templateId/:sessionId',
    component: () => import('../views/CustomReport.vue'),
    meta: {
      name: 'custom-report',
    },
  },
];

export default reportRoutes;
