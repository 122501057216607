<template>
  <svg v-if="small" width="801" height="235" viewBox="0 0 801 235" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect opacity="0.5" y="108.1" width="120.15" height="126.9" fill="#F5F5F5" />
    <rect opacity="0.5" x="136.17" y="148.638" width="120.15" height="85.775" fill="#F5F5F5" />
    <rect opacity="0.5" x="272.34" y="108.1" width="120.15" height="126.9" fill="#F5F5F5" />
    <rect opacity="0.5" x="408.51" y="82.8373" width="120.15" height="152.162" fill="#F5F5F5" />
    <rect opacity="0.5" x="544.68" y="46.9999" width="120.15" height="188" fill="#F5F5F5" />
    <rect opacity="0.5" x="680.85" width="120.15" height="235" fill="#F5F5F5" />
  </svg>

  <svg v-else width="800" height="400" viewBox="0 0 800 400" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect opacity="0.5" y="184" width="120" height="216" fill="#F5F5F5" />
    <rect opacity="0.5" x="136" y="253" width="120" height="146" fill="#F5F5F5" />
    <rect opacity="0.5" x="272" y="184" width="120" height="216" fill="#F5F5F5" />
    <rect opacity="0.5" x="408" y="141" width="120" height="259" fill="#F5F5F5" />
    <rect opacity="0.5" x="544" y="80" width="120" height="320" fill="#F5F5F5" />
    <rect opacity="0.5" x="680" width="120" height="400" fill="#F5F5F5" />
  </svg>
</template>

<script>
export default {
  props: {
    small: Boolean,
  },
};
</script>
