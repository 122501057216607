<template>
  <svg
    width="14"
    height="13"
    viewBox="0 0 14 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      y="7"
      width="4"
      height="6"
      fill="#272A32"
    />
    <rect
      x="5"
      y="10"
      width="4"
      height="3"
      fill="#272A32"
    />
    <rect
      x="5"
      width="4"
      height="9"
      fill="#272A32"
    />
    <rect
      x="10"
      y="4"
      width="4"
      height="9"
      fill="#272A32"
    />
    <rect
      x="5"
      y="2"
      width="4"
      height="9"
      fill="#272A32"
    />
  </svg>
</template>
