import { memoize } from '@/lib/helpers';
import i18next from '@/plugins/i18n';
import { SEGMENT_PATH, TYPES_PATH } from '@/constants/endpoints';
import { getJson, deleteJson, postJson, putJson } from '../request';

const $t = i18next.t.bind(i18next);

export async function getSegments(filter = '') {
  const { segments } = await getJson(`${SEGMENT_PATH}${filter.length > 0 ? `?filter=${filter}` : ''}`);
  return segments;
}

export const getTypes = memoize(async () => {
  const { types } = await getJson(`${TYPES_PATH}`);
  return types;
});

export const getAllOfType = memoize(async (type) => {
  const { [type]: data } = await getJson(`${TYPES_PATH}/${type}`);
  return data;
});

export async function getSegment(id, accept) {
  try {
    const segment = await getJson(`${SEGMENT_PATH}/${id}${accept ? `?accept=${accept}` : ''}`);

    return {
      ...segment,
      name: $t(`segments.${segment.id}`) !== `segments.${segment.id}` ? $t(`segments.${segment.id}`) : segment.name,
      description: $t(`segments.${segment.id}_description`) !== `segments.${segment.id}_description` ? $t(`segments.${segment.id}_description`) : segment.description,
    };
  } catch (e) {
    if (accept) {
      return getSegment(id);
    }
    throw e;
  }
}

export async function createSegment(data) {
  return postJson(`${SEGMENT_PATH}`, {
    ...data,
  });
}

export async function updateSegment(id, data) {
  return putJson(`${SEGMENT_PATH}/${id}`, {
    ...data,
  });
}

export async function updateSegmentPermissions({ id, permissions, is, template }) {
  return putJson(`${SEGMENT_PATH}/${id}?template=${template}`, {
    permissions,
    is,
  });
}

export async function deleteSegment(id) {
  return deleteJson(`${SEGMENT_PATH}/${id}`);
}

export async function unsubscribe(segmentId) {
  await deleteJson(`${SEGMENT_PATH}/${segmentId}/unsubscribe`);
}
