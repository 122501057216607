import { createStore, createLogger } from 'vuex';
import app from './modules/app';
import subscription from './modules/subscription';
import report from './modules/report';
import reports from './modules/reports';
import metrics from './modules/metrics';
import segments from './modules/segments';

const debug = process.env.NODE_ENV === 'development';

export default createStore({
  modules: {
    app,
    reports,
    report,
    subscription,
    metrics,
    segments,
  },
  strict: debug,
  plugins: debug ? [createLogger()] : [],
});
