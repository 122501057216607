export const loadStyles = (url) => {
  const element = document.createElement('link');
  element.setAttribute('rel', 'stylesheet');
  element.setAttribute('type', 'text/css');
  element.setAttribute('href', url);
  document.getElementsByTagName('head')[0].appendChild(element);
};

export const setFavicon = (url) => {
  const favicon = document.getElementById('favicon');
  favicon.href = url;
};

export const setTitle = (title) => {
  document.title = title;
};

export const isTouchDevice = () => matchMedia('(hover: none)').matches;
