<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4 6C4 4.89543 4.89543 4 6 4H15.259C15.9767 4 16.6394 4.38457 16.9955 5.00772L18.1318 6.99614L19.7365 9.80442L20.6047 9.30828L19.7365 9.80442C19.9092 10.1066 20 10.4486 20 10.7967V18C20 19.1046 19.1046 20 18 20H6C4.89543 20 4 19.1046 4 18V6Z"
      :stroke="color"
      stroke-width="2"
    />
    <rect
      x="6"
      y="3"
      width="8"
      height="5"
      rx="1"
      :fill="color"
    />
    <circle
      cx="12"
      cy="13"
      r="2"
      :stroke="color"
      stroke-width="2"
    />
  </svg>
</template>

<script>
export default {
  props: {
    disabled: Boolean,
  },
  computed: {
    color() {
      return this.disabled ? '#8d8f93' : '#000';
    },
  },
};
</script>
