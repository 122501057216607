<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="126" height="126" x="0px" y="0px" viewBox="0 0 34.02 39.69" xml:space="preserve">
    <path d="M21.78,1.21H4.57c-1.49,0-2.71,1.22-2.71,2.71v31.85c0,1.49,1.22,2.71,2.71,2.71h24.88 c1.49,0,2.71-1.22,2.71-2.71V11.98L21.78,1.21z M28.78,34.67H5.24V5.01h14.39v9h9.16V34.67z" fill="#646b71" />
    <polygon points="16.6,9.54 14.36,7.33 11.67,10.07 8.93,7.39 6.72,9.63 9.47,12.32 6.78,15.06 9.03,17.27 11.71,14.53 14.45,17.21 16.66,14.97 13.92,12.28" fill="#646b71" />
    <rect x="17.9" y="22.62" width="9.18" height="3.16" fill="#646b71" />
    <rect x="17.9" y="27.47" width="9.18" height="3.16" fill="#646b71" />
    <polygon points="6.75,29.14 9,31.35 16.04,24.17 13.79,21.96" fill="#646b71" />
    <path d="M10.23,24.03c0-0.89-0.72-1.61-1.61-1.61c-0.89,0-1.61,0.72-1.61,1.61c0,0.89,0.72,1.61,1.61,1.61 C9.51,25.64,10.23,24.92,10.23,24.03z" fill="#646b71" />
    <path d="M13.84,28.03c-0.89,0-1.61,0.72-1.61,1.61c0,0.89,0.72,1.61,1.61,1.61c0.89,0,1.61-0.72,1.61-1.61 C15.45,28.75,14.73,28.03,13.84,28.03z" fill="#646b71" />
  </svg>
</template>
