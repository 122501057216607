import numeral from 'numeral';
import 'numeral/locales/fr';
import 'numeral/locales/de';
import 'numeral/locales/it';
import 'numeral/locales/es';
import { language } from '@/plugins/locale';

numeral.locale(language);

export default numeral;
