import VueTippy from 'vue-tippy';

export default VueTippy;
export const tippyConfig = {
  defaultProps: {
    arrow: true,
    flipDuration: 0,
    duration: 0,
    touch: false,
    placement: 'bottom',
    boundary: 'viewport',
    onShow: (options) => {
      // Do not show tippy when there's no content
      return options.props.content !== 'false' && !!options.props.content;
    },
  },
};
