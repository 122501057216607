import without from 'lodash/without';
import find from 'lodash/find';
import i18next from '@/plugins/i18n';
import { getSubscriptions, addSubscription, editSubscription, unsubscribe } from '@/services/api/subscription';

const $t = i18next.t.bind(i18next);

const state = {
  loaded: false,
  subscriptions: [],
};

const mutations = {
  SET_SUBSCRIPTIONS(state, subscriptions) {
    Object.assign(state, { subscriptions, loaded: true });
  },
  ADD_SUBSCRIPTION(state, subscription) {
    state.subscriptions = [...state.subscriptions, subscription];
  },
  EDIT_SUBSCRIPTION(state, nextSubscription) {
    state.subscriptions = state.subscriptions.map((subscription) => {
      if (nextSubscription.id === subscription.id) {
        return nextSubscription;
      }
      return subscription;
    });
  },
  DELETE_SUBSCRIPTION(state, { id: subscriptionId }) {
    state.subscriptions = without(state.subscriptions, find(state.subscriptions, ({ id }) => id === subscriptionId));
  },
};

const actions = {
  async fetchSubscriptions({ commit }) {
    const { schedulers } = await getSubscriptions();
    commit('SET_SUBSCRIPTIONS', schedulers);
  },
  async subscribe({ getters, commit }, { id, ...schedulerParams }) {
    const data = {
      ...schedulerParams,
      template: getters.template,
    };
    const content = id ? 'subscription.updated' : 'subscription.you_subscribed';
    if (id) {
      const subscription = await editSubscription(id, data);
      commit('EDIT_SUBSCRIPTION', subscription);
    } else {
      const subscription = await addSubscription(data);
      commit('ADD_SUBSCRIPTION', subscription);
    }
    commit('showToast', {
      precontent: $t('subscription.success'),
      content: $t(content),
    });
  },
  async unsubscribe({ commit }, id) {
    await unsubscribe(id);
    commit('DELETE_SUBSCRIPTION', { id });
    commit('showToast', {
      precontent: $t('subscription.success'),
      content: $t('subscription.you_unsubscribed'),
    });
  },
};

const getters = {
  isSubscriptionsLoaded: ({ loaded }) => loaded,
  subscriptions: ({ subscriptions }) => subscriptions,
  isSubscribedById: ({ subscriptions }) => (entityId, entityPath) => {
    return subscriptions.some(({ entity, path }) => {
      return (entity.id && entity.id === entityId) || path === entityPath;
    });
  },
  subscriptionById: ({ subscriptions }) => (entityId, entityPath) => {
    return subscriptions.find(({ entity, path }) => {
      return (entity.id && entity.id === entityId) || path === entityPath;
    });
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
