<template>
  <svg
    width="144"
    height="144"
    viewBox="0 0 144 144"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M36 12C29.4345 12 24 17.4345 24 24V120C24 126.566 29.4345 132 36 132H108C114.566 132 120 126.566 120 120V48L84 12H36ZM36 24H78V54H108V120H36V24ZM48 72V108H60V72H48ZM66 84V108H78V84H66ZM84 96V108H96V96H84Z"
      fill="#A4ABB4"
    />
  </svg>
</template>
