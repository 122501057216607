import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import sortBy from 'lodash/sortBy';
import size from 'lodash/size';
import i18next from '@/plugins/i18n';
import { memoize } from '@/lib/helpers';
import { getLoyaltyTree } from '@/services/api/loyalty';

const $t = i18next.t.bind(i18next);
const PROGRAM_PREFIX = 'program/';
const POOL_PREFIX = 'pool/';
const INCENTIVE_PREFIX = 'incentive/';

class LoyaltyService {
  constructor() {
    this.programs = [];
    this.pools = [];
    this.incentives = [];
    this.isInit = false;
    this.init = memoize(this.init.bind(this));
    this.unzipIncentives = memoize(this.unzipIncentives.bind(this));
  }

  /*
    Method must be executed before call any other helper functions
   */
  async init() {
    const { programs, pools, incentives } = await getLoyaltyTree();
    this.programs = programs;
    this.pools = pools;
    this.incentives = incentives;
    this.isInit = true;
  }

  /*
    Method returns top value
    @return { array } loyalty ids
   */
  /* eslint-disable-next-line class-methods-use-this */
  getTopValue() {
    return ['all'];
  }

  /*
  Method returns tree options
  @return { array } loyalty vue-treeselect options
 */
  getOptions() {
    const options = sortBy([
      ...this.programs.map((program) => {
        const programChildren = [
          ...program.pools.map((pool) => {
            const children = pool.incentives.map((incentive) => ({
              id: `${INCENTIVE_PREFIX}${incentive.id}`,
              label: incentive.name,
            }));
            return {
              id: `${POOL_PREFIX}${pool.id}`,
              label: pool.name,
              children: size(children) ? children : undefined,
            };
          }),
          ...get(program, 'incentives', []),
        ];
        return {
          id: `${PROGRAM_PREFIX}${program.id}`,
          label: program.name,
          children: size(programChildren) ? programChildren : undefined,
        };
      }),
      ...this.pools.map((pool) => {
        const children = pool.incentives.map((incentive) => ({
          id: `${INCENTIVE_PREFIX}${incentive.id}`,
          label: incentive.name,
        }));
        return {
          id: `${POOL_PREFIX}${pool.id}`,
          label: pool.name,
          children: size(children) ? children : undefined,
        };
      }),
      ...this.incentives.map((incentive) => ({
        id: `${INCENTIVE_PREFIX}${incentive.id}`,
        label: incentive.name,
      })),
    ], 'label');

    return [{
      id: 'all',
      label: $t('loyalty.all'),
      children: options.length ? options : undefined,
    }];
  }

  /*
    Method converts `loyalty` filter to plain array (vue-treeselect value)
    @param { object } loyalty object with selected programs, pools and incentives
    @returns { array } vue-treeselect value
   */
  parseValueToTreeValue(value, isTopSelected = true) {
    if (size(value)) {
      return [
        ...get(value, 'program', []).map((id) => `${PROGRAM_PREFIX}${id}`),
        ...get(value, 'pool', []).map((id) => `${POOL_PREFIX}${id}`),
        ...get(value, 'incentive', []).map((id) => `${INCENTIVE_PREFIX}${id}`),
      ];
    }
    return isTopSelected ? this.getTopValue() : [];
  }

  /*
    Method converts plain array (vue-treeselect value) to `loyalty` filter
    @param { array } vue-treeselect value
    @returns { object } loyalty object with selected programs, pools and incentives
   */
  parseTreeValueToValue(value) {
    const programs = value.filter((item) => item.includes(PROGRAM_PREFIX)).map((item) => item.replace(PROGRAM_PREFIX, ''));
    const pools = value.filter((item) => item.includes(POOL_PREFIX)).map((item) => item.replace(POOL_PREFIX, ''));
    const incentives = value.filter((item) => item.includes(INCENTIVE_PREFIX)).map((item) => item.replace(INCENTIVE_PREFIX, ''));

    return isEqual(value, this.getTopValue()) ? {} : {
      ...(programs.length ? { program: programs } : {}),
      ...(pools.length ? { pool: pools } : {}),
      ...(incentives.length ? { incentive: incentives } : {}),
    };
  }

  /*
    Method takes tree values and returns the flatten array with incentive id and name
    @returns { array } list of all incentives in flatten array { id: ..., name: ... }
  */
  unzipIncentives() {
    return [
      ...this.programs.reduce((incentives, program) => {
        return [
          ...program.pools.reduce((accIncentives, pool) => {
            return accIncentives.concat(pool.incentives);
          }, []),
          ...incentives,
        ];
      }, []),
      ...this.pools.reduce((incentives, pool) => {
        return incentives.concat(pool.incentives);
      }, []),
      ...this.incentives,
    ];
  }

  /*
    Method takes tree values and returns the flatten array with pool id and name
    @returns { array } list of all pools in flatten array { id: ..., name: ... }
  */
  unzipPools() {
    return [
      ...this.programs.reduce((pools, program) => {
        return [
          ...program.pools,
          ...pools,
        ];
      }, []),
      ...this.pools,
    ];
  }

  /*
    Method takes array of tree ids and returns names joined
    @param { object } loyalty object with selected programs, pools and incentives
    @returns { string } formatted names of incentives
   */
  getLabel(value) {
    const treeValue = this.parseValueToTreeValue(value);
    const pools = this.unzipPools();
    const incentives = this.unzipIncentives();

    return treeValue.map((item) => {
      if (item === 'all') {
        return $t('loyalty.all');
      }
      if (item.includes(PROGRAM_PREFIX)) {
        const program = this.programs.find(({ id }) => id === item.replace(PROGRAM_PREFIX, ''));
        return get(program, 'name', program);
      }
      if (item.includes(POOL_PREFIX)) {
        const pool = pools.find(({ id }) => id === item.replace(POOL_PREFIX, ''));
        return get(pool, 'name', pool);
      }
      if (item.includes(INCENTIVE_PREFIX)) {
        const incentive = incentives.find(({ id }) => id === item.replace(INCENTIVE_PREFIX, ''));
        return get(incentive, 'name', incentive);
      }
      return item;
    }).join(', ');
  }
}

export default new LoyaltyService();
