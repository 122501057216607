import fetch from 'isomorphic-fetch';
import { LOGOUT_PATH, USER_PATH } from '@/constants/endpoints';
import { putJson, postJson, getJson, deleteJson, post, headers } from '@/services/request';

export async function getUser() {
  let response;
  try {
    response = await fetch(`${USER_PATH}/current`, { headers: headers() });
  } catch (e) {
    response = { ok: false };
  }
  if (!response.ok) {
    response = await fetch(`${USER_PATH}/current`, { headers: headers() });
  }
  if (!response.ok) {
    throw response;
  }
  return response.json();
}

export function getUsers() {
  return getJson(`${USER_PATH}`);
}

export function inviteUsers(users, { template }) {
  return postJson(`${USER_PATH}/invite`, { users, template });
}

export function reinviteUser(users, { template }) {
  return postJson(`${USER_PATH}/reinvite`, { users, template });
}

export function restorePassword({ client, template, email }) {
  return fetch(`${USER_PATH}/forgot`, {
    method: 'POST',
    headers: headers(false),
    body: JSON.stringify({ email, client, template }),
  });
}

export async function setPassword({ client, token, userId, password }) {
  const response = await fetch(`${USER_PATH}/set_password`, {
    method: 'POST',
    headers: headers(false),
    body: JSON.stringify({ user_id: userId, client, token, password }),
  });
  return response.json();
}

export async function setUser(userId, { firstName, lastName, settings, currentPassword, newPassword }) {
  return putJson(`${USER_PATH}/${userId}`, {
    ...((firstName || lastName) ? { name: { first: firstName, last: lastName } } : {}),
    ...(settings ? { settings } : {}),
    old_password: currentPassword,
    new_password: newPassword,
    compare_password: newPassword,
  });
}

export async function acceptInvitation(client, acceptToken) {
  const response = await fetch(`${USER_PATH}/accept`, {
    method: 'POST',
    headers: headers(false),
    body: JSON.stringify({ client, accept_token: acceptToken }),
  });
  return response.json();
}

export async function joinTeam({ client, email, password, name, acceptToken }) {
  const response = await fetch(`${USER_PATH}/register`, {
    method: 'POST',
    headers: headers(false),
    body: JSON.stringify({ client, email, password, name, accept_token: acceptToken }),
  });
  return response.json();
}

export async function updateUser(userId, user) {
  return putJson(`${USER_PATH}/${userId}`, user);
}

export function deleteUser(userId) {
  return deleteJson(`${USER_PATH}/${userId}`);
}

export async function logout() {
  return post(LOGOUT_PATH);
}
