<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="13" y="3" width="2" height="17" :fill="color" />
    <path fill-rule="evenodd" clip-rule="evenodd" d="M12 6L4 6C2.84621 6 2 6.84621 2 8V15.8889C2 17.0427 2.84621 18 4 18H12V16H4V8H12V6ZM16 16H19V8H16V6L19 6C20.1538 6 21 6.84621 21 8V15.8889C21 17.0427 20.1538 18 19 18H16V16Z" :fill="color" />
    <rect x="11" y="2" width="6" height="2" rx="1" :fill="color" />
    <rect x="11" y="20" width="6" height="2" rx="1" :fill="color" />
  </svg>
</template>

<script>
export default {
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    color() {
      return this.disabled ? '#8d8f93' : '#000';
    },
  },
};
</script>
