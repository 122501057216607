import last from 'lodash/last';
import { SUMMARY_PATH } from '@/constants/endpoints';
import { postJson } from '../request';

const abortControllers = [];

export function getSummaryData({ segment, geo, org, range, trend, currency, filter, metric, include }) {
  abortControllers.push(new AbortController());
  return postJson(SUMMARY_PATH, { geo, segment, org, range, trend, currency, filter, metric, include }, {
    signal: last(abortControllers).signal,
  });
}

export const abortLatestSummaryData = () => {
  if (abortControllers.length) {
    const abortController = abortControllers.pop();
    abortController.abort();
  }
};

export const abortAllSummaryData = () => {
  while (abortControllers.length) {
    abortLatestSummaryData();
  }
};
