import isNaN from 'lodash/isNaN';
import get from 'lodash/get';
import _memoize from 'lodash/memoize';
import { locale } from '@/plugins/locale';
import { USD } from '@/constants/currencies';
import { METRIC_TYPE_PERCENT, METRIC_TYPE_CURRENCY, METRIC_TYPE_NUMBER } from '@/constants/metrics';

export function escapeSegmentForUrl(segment) {
  if (!segment || !segment.indexOf) return segment;
  if (segment.indexOf('%') >= 0) return segment; // Already encoded

  const encoded = encodeURIComponent(segment);
  return encoded.replace('%3D', '=');
}

export const timeDisplayFormats = {
  year: 'YYYY',
  week: 'MMM D YYYY',
  week_table: 'MMMM D, YYYY',
  month: 'MMM YYYY',
  short_month: 'MMM',
  quarter: '[Q]Q YYYY',
  short_quarter: '[Q]Q',
  auto_quarter: 'MMM',
  day: 'ddd, MMM D, YYYY',
  day_table: 'dddd, MMMM D, YYYY',
  hour: 'ddd ha',
  hour_table: 'dddd ha',
};

export function sortWidgetsByCoordinates(a, b) {
  if (a.coordinates.y > b.coordinates.y) {
    return 1;
  }
  if (a.coordinates.y < b.coordinates.y) {
    return -1;
  }
  if (a.coordinates.x > b.coordinates.x) {
    return 1;
  }
  if (a.createdAt < b.createdAt) {
    return -1;
  }
  if (a.createdAt > b.createdAt) {
    return 1;
  }
  return 0;
}

function getShort(value) {
  if (Math.abs(value) > 9000 && Math.abs(value) <= 9000000) {
    return { value: Math.round(value / 1000), prefix: 'K' };
  }
  if (Math.abs(value) > 9000000) {
    return { value: Math.round(value / 1000000), prefix: 'M' };
  }
  return {
    value,
    prefix: '',
  };
}

export function formatPercent(percent, options) {
  const short = get(options, 'short', false);
  const decimal = get(options, 'decimal', 1);
  if (percent !== 0 && Math.abs(percent) < 100) {
    return `${(percent).toFixed(decimal)}%`;
  }
  if (short) {
    const { value, prefix } = getShort(percent);
    return `${new Intl.NumberFormat(locale, { style: 'decimal', minimumFractionDigits: 0 }).format(value)}${prefix}%`;
  }
  return `${new Intl.NumberFormat(locale, { style: 'decimal', minimumFractionDigits: 0 }).format(Math.round(percent))}%`;
}

export function formatMoney(amount, options) {
  const currency = get(options, 'currency', USD);
  if (isNaN(parseFloat(amount))) return amount;
  if (!currency && amount === 0) return new Intl.NumberFormat(locale, { style: 'currency', currency: USD }).format(0).replace(/[^\d,.]/g, '');
  return new Intl.NumberFormat(
    locale,
    {
      style: 'currency',
      currency,
    },
  ).format(parseFloat(amount));
}

export function formatMoneyRounded(amount, options) {
  const currency = get(options, 'currency', USD);
  const short = get(options, 'short', false);
  if (isNaN(parseFloat(amount))) return amount;
  if (!currency && amount === 0) return '0';
  if (short) {
    const { value, prefix } = getShort(amount);
    return `${new Intl.NumberFormat(locale, { style: 'currency', currency, minimumFractionDigits: 0 }).format(value)}${prefix}`;
  }
  return new Intl.NumberFormat(locale, { style: 'currency', currency, minimumFractionDigits: 0 }).format(Math.round(parseFloat(amount)));
}

export function formatNumberRounded(amount, options) {
  const short = get(options, 'short', false);
  if (isNaN(parseFloat(amount))) return amount;
  if (short) {
    const { value, prefix } = getShort(amount);
    return `${new Intl.NumberFormat(locale, { style: 'decimal', minimumFractionDigits: 0 }).format(value)}${prefix}`;
  }
  return new Intl.NumberFormat(locale, { style: 'decimal', minimumFractionDigits: 0 }).format(Math.round(parseFloat(amount)));
}

export function formatMetric(value, { format, short, currency = USD }) {
  if (format === METRIC_TYPE_PERCENT) {
    return formatPercent(value, { short });
  }
  if (format === METRIC_TYPE_NUMBER) {
    return formatNumberRounded(value, { short });
  }
  if (format === METRIC_TYPE_CURRENCY) {
    return value >= 100 ? formatMoneyRounded(value, { currency, short }) : formatMoney(value, { currency, short });
  }
  return value;
}

export const getCurrencySymbol = (currency = USD) => {
  return (0).toLocaleString(locale, {
    style: 'currency',
    currency,
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).replace(/\d/g, '').trim();
};

export function decimalPlaces(num) {
  const match = `${num}`.match(/(?:\.(\d+))?(?:[eE]([+-]?\d+))?$/);
  if (!match) { return 0; }
  return Math.max(
    0,
    // Number of digits right of decimal point.
    (match[1] ? match[1].length : 0)
    // Adjust for scientific notation.
    - (match[2] ? +match[2] : 0),
  );
}

export const generateAcronym = (str) => {
  if (str && str.length) {
    return str.split(/\s/).reduce((response, word) => {
      return response + word.slice(0, 1);
    }, '');
  }
  return '';
};

export function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
}

const memoizedFunctions = [];
export function memoize(fn) {
  const wrapped = _memoize((...args) => {
    return fn(...args);
  });
  memoizedFunctions.push(wrapped);
  return wrapped;
}

export function clearMemoize() {
  memoizedFunctions.forEach((fn) => {
    fn.cache.clear();
  });
}
