<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M18.369 3.29L20.709 5.63C21.099 6.02 21.099 6.65 20.709 7.04L19.5861 8.16289C19.1956 8.55342 18.5624 8.55342 18.1719 8.16289L15.8361 5.82711C15.4456 5.43658 15.4456 4.80342 15.8361 4.41289L16.959 3.29C17.149 3.1 17.399 3 17.659 3C17.919 3 18.169 3.09 18.369 3.29ZM3.29192 16.9571C3.10438 17.1446 2.99902 17.399 2.99902 17.6642V20C2.99902 20.5523 3.44674 21 3.99902 21H6.33481C6.60003 21 6.85438 20.8946 7.04192 20.7071L17.1019 10.6471C17.4924 10.2566 17.4924 9.62342 17.1019 9.23289L14.7661 6.89711C14.3756 6.50658 13.7424 6.50658 13.3519 6.89711L3.29192 16.9571ZM5.91902 19H4.99902V18.08L14.059 9.02L14.979 9.94L5.91902 19Z"
      :fill="color"
    />
  </svg>
</template>

<script>
export default {
  name: 'PencilIcon',
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      disabledColor: '#8d8f93',
    };
  },
  computed: {
    color() {
      return this.disabled ? this.disabledColor : 'black';
    },
  },
};
</script>
