import { createRouter, createWebHistory } from 'vue-router';
import pick from 'lodash/pick';
import omit from 'lodash/omit';
import has from 'lodash/has';
import isNil from 'lodash/isNil';
import { clearClassicLoginFlag, clearPathBeforeLogin, getPathBeforeLogin, setClassicLoginFlag } from '@/services/storage';
import reportRoutes from './report';
import graphRoutes from './graph-report';
import settingsRoutes from './settings';
import store from '../store';

export const routes = [
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/Auth/Login.vue'),
    meta: {
      isPublic: true,
    },
  },
  {
    path: '/password-recovery',
    name: 'password-recovery',
    component: () => import('../views/Auth/PasswordRecovery.vue'),
    meta: {
      isPublic: true,
    },
  },
  {
    path: '/user/:userId/reset',
    name: 'password-reset',
    component: () => import('../views/Auth/PasswordReset.vue'),
    meta: {
      isPublic: true,
    },
  },
  {
    path: '/user/:userId',
    component: () => import('../views/Auth/Signup.vue'),
    meta: {
      isPublic: true,
    },
  },
  {
    path: '/dashboards',
    name: 'dashboards',
    component: () => import('../views/Dashboards'),
    alias: [
      '/dashboard/',
    ],
  },
  {
    path: '/dashboard/:id',
    name: 'dashboard',
    component: () => import('../views/Dashboards/Dashboard.vue'),
  },
  {
    path: '/segments',
    name: 'segments',
    component: () => import('../views/Segments/Segments.vue'),
  },
  {
    path: '/segment/:id',
    name: 'segment',
    component: () => import('../views/Segments/Segment.vue'),
  },
  {
    path: '/reports',
    name: 'reports',
    component: () => import('../views/Reports.vue'),
  },
  {
    path: '/reports/:id',
    name: 'reportaccept',
    component: () => import('../views/ReportAccept.vue'),
  },
  {
    path: '/generate-report/:type/:report/:tableId?',
    name: 'generate-report',
    component: () => import('../views/GenerateReport.vue'),
  },
  {
    path: '/loyalty',
    name: 'loyalty',
    component: () => import('../views/Loyalty.vue'),
  },
  {
    path: '/loyalty/:type/:id/:sessionId?',
    name: 'loyalty-details',
    component: () => import('../views/LoyaltyDetails.vue'),
    meta: {
      name: 'loyalty-details',
    },
  },
  {
    path: '/metrics',
    name: 'metrics',
    component: () => import('../views/Metrics.vue'),
  },
  {
    path: '/metrics/:id',
    name: 'metric',
    component: () => import('../views/Metric.vue'),
  },
  {
    path: '/tables',
    name: 'tables',
    component: () => import('../views/Tables.vue'),
  },
  {
    path: '/tables/:id',
    name: 'table',
    component: () => import('../views/Table.vue'),
  },
  ...reportRoutes,
  ...graphRoutes,
  ...settingsRoutes,
  {
    path: '/:pathMatch(.*)*',
    redirect: '/dashboards',
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (!to.meta.name || to.meta.name !== from.meta.name) {
      return { x: 0, y: 0 };
    }
    return savedPosition;
  },
});

router.isReady().then(() => {
  if (store.getters.isIntercomEnabled) {
    router.afterEach(() => {
      window.Intercom('update');
    });
  }

  // Pulls a JWT token off the query string
  // Form: ?token=TOKEN&refresh_token=REFRESH_TOKEN&expiry=1583515875&fpSession=true
  const to = router.currentRoute.value;
  let query = {
    ...to.query,
  };

  if (to.hash && to.hash.length) {
    const fauxQuery = {};
    const pieces = to.hash.substring(1).split('&');
    pieces.forEach((piece) => {
      const [key, value] = piece.split('=');
      if (key && value) {
        if (key === 'expiry') {
          fauxQuery.expiry = parseInt(value, 10);
        } else if (key === 'fpSession') {
          fauxQuery.fpSession = value.toString() === 'true';
        } else {
          fauxQuery[key] = decodeURIComponent(value);
        }
      }
    });

    query = {
      ...fauxQuery,
      ...query,
    };
  }

  if (has(query, 'client')) {
    store.commit('SET_CLIENT_ID', query.client);
  }

  if (to.path === '/login') {
    setClassicLoginFlag();
  }

  if (query && query.token && query.refresh_token && query.expiry) {
    const redirectTo = getPathBeforeLogin();
    clearPathBeforeLogin();

    store.commit('SET_SESSION', pick(query, 'token', 'refresh_token', 'expiry', 'fpSession'));
    clearClassicLoginFlag();

    router.replace({
      ...to,
      path: !isNil(redirectTo) && redirectTo.startsWith('/') ? redirectTo : '/dashboards',
      query: omit(to.query, 'token', 'refresh_token', 'expiry', 'fpSession'),
      hash: '',
    });
  }
});

export default router;
