<template>
  <div class="progress-notification">
    <div class="progress-notification-content">
      <div class="progress-notification-text">
        <UpdateIcon />
        {{ content }}
      </div>
      <a
        class="hide invert"
        @click="hide"
      >
        {{ $t('segments.hide') }}
      </a>
    </div>
    <div class="loader-bar">
      <div class="loader-bar-line" :class="{ progress }" />
    </div>
  </div>
</template>

<script>
import { UpdateIcon } from '@/components/Icons';

export default {
  components: {
    UpdateIcon,
  },
  props: {
    content: String,
  },
  emits: [
    'hide',
  ],
  data() {
    return {
      progress: false,
    };
  },
  mounted() {
    setTimeout(() => {
      this.progress = true;
    }, 1);
  },
  methods: {
    hide() {
      this.$emit('hide');
    },
  },
};
</script>

<style scoped lang="scss" src="../../styles/components/ProgressNotification.scss" />
