import VCalendar from 'v-calendar';
import { DESKTOP } from '@/constants/resolutions';

export default VCalendar;
export const calendarConfig = {
  screens: {
    twoCol: '560px',
    threeCol: `${DESKTOP}px`,
  },
};
