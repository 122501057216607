<template>
  <svg class="LoadingLogo" data-testid="loading-logo" :width="dimensions.width" :height="dimensions.height"
    viewBox="0 0 500 500" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path class="bottom" fill="#EDEDED"
      d="M 318.806 247.899 C 318.806 263.59 310.562 275.941 298.646 282.821 C 286.731 289.702 271.847 290.779 258.326 282.818 C 244.675 275.089 238.167 261.66 238.167 247.9 C 238.167 234.14 244.675 220.711 258.326 212.981 C 264.506 209.385 271.341 207.577 278.486 207.577 C 289.732 207.577 299.613 212.005 306.996 219.387 C 314.378 226.77 318.806 236.652 318.806 247.899 Z M 384.495 80.708 L 376.666 74.468 L 382.199 82.799 C 389.482 93.766 390.665 108.209 382.514 120.797 C 376.349 130.707 365.986 136.687 354.341 137.081 L 187.824 137.081 L 187.824 394.426 C 187.929 407.816 181.577 418.613 172.104 425.57 C 162.634 432.525 150.536 435.259 138.548 431.687 L 126.996 428.247 L 137.29 434.539 C 170.053 454.565 208.776 466.21 250 466.21 C 309.589 466.21 363.836 441.922 402.875 402.882 C 441.912 363.843 466.196 309.591 466.196 249.999 C 466.196 181.567 434.125 120.281 384.495 80.708 Z" />
    <path class="right" fill="#EDEDED"
      d="M 250 33.789 C 290.88 33.789 329.302 45.24 361.888 64.958 L 370.138 69.95 L 360.731 67.837 C 358.649 67.37 356.571 67.092 354.335 67.002 L 149.121 67.002 C 145.343 66.958 141.798 67.452 138.338 68.436 L 127.312 71.575 L 137.089 65.584 C 169.896 45.481 208.693 33.789 250 33.789 Z" />
    <path class="left" fill="#EDEDED"
      d="M 33.804 249.999 C 33.804 181.609 65.833 120.36 115.413 80.784 L 124.532 73.504 L 117.667 82.933 C 112.976 89.378 110.323 97.113 110.444 105.695 L 110.444 394.426 C 110.403 402.848 113.043 410.663 117.88 417.233 L 124.903 426.77 L 115.631 419.39 C 65.928 379.82 33.804 318.49 33.804 249.999 Z" />
  </svg>
</template>

<script>
export default {
  name: 'LoadingLogo',
  props: {
    width: {
      type: Number,
      default: 30,
    },
    height: {
      type: Number,
      default: 35,
    },
    size: {
      type: String,
      default: undefined,
    },
  },
  data() {
    let dimensions = {
      width: this.width,
      height: this.height,
    };

    switch (this.size) {
      case 'big':
        dimensions = {
          width: 60,
          height: 70,
        };
        break;
      case 'tiny':
        dimensions = {
          width: 20,
          height: 25,
        };
        break;
    }

    return {
      dimensions,
    };
  },
};
</script>

<style scoped lang="scss" src="../../styles/components/LoadingLogo.scss" />
