<template>
  <div :class="`${(`lang-${locale}`).toLowerCase()} template-${template}`">
    <Logo v-if="!isDataPreloaded" class="logo-preloader" />

    <Notification
      :visible="toast.visible"
      :precontent="toast.precontent"
      :content="toast.content"
      :type="toast.type"
      @remove="closeToast"
    />
    <ProgressNotification
      v-if="progressNotification.visible"
      :content="progressNotification.content"
      @hide="hideProgressNotification"
    />
    <router-view v-if="isDataPreloaded" v-slot="{ Component }">
      <component :is="Component" />
    </router-view>
    <div v-if="!isDataPreloaded" class="loader">
      <LoadingLogo size="big" />
    </div>
    <div id="gear-dropdown" class="dropdown-target" />
    <div id="advanced-dropdown" class="dropdown-target" />
  </div>
</template>

<script>
import { useRouter, useRoute } from 'vue-router';
import { mapGetters, mapActions } from 'vuex';
import qs from 'qs';
import get from 'lodash/get';
import Notification from '@/components/Notification';
import ProgressNotification from '@/components/Notification/ProgressNotification.vue';
import Logo from '@/components/Navbar/Logo.vue';
import LoadingLogo from '@/components/LoadingLogo';
import { TEMPLATE_FREEDOMPAY, TEMPLATE_LLOYDS } from '@/constants/templates';
import { loadStyles, setFavicon, setTitle } from '@/lib/dom-helpers';
import { authenticationFailed } from '@/services/request-helpers';

export default {
  components: {
    Logo,
    LoadingLogo,
    Notification,
    ProgressNotification,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();

    return {
      router,
      route,
    };
  },
  data() {
    return {
      isDataPreloaded: false,
    };
  },
  computed: {
    ...mapGetters({
      isLoggedIn: 'isLoggedIn',
      isPrint: 'isPrint',
      isPrintEmail: 'isPrintEmail',
      template: 'template',
      toast: 'toast',
      locale: 'getLocale',
      progressNotification: 'getProgressNotification',
      copy: 'copy',
    }),
  },
  mounted() {
    this.updateTemplate();
    this.updateIndustry();

    this.router.isReady().then(async () => {
      if (!get(this.route, 'meta.isPublic', false)) {
        await this.beforeLoad();
        if (this.isLoggedIn) {
          this.isDataPreloaded = true;
        } else {
          authenticationFailed();
        }
      } else {
        this.isDataPreloaded = true;
      }

      this.checkPrintMode();
      if (this.isPrint) {
        this.switchToPrintMode();
      }

      if (get(this.route, 'query.lng', false)) {
        await this.setUser({ settings: { language: this.route.query.lng } });
      }

      await this.getTemplate();

      this.setLocale();
      this.updateTemplateStyles();
    });
  },
  methods: {
    checkPrintMode() {
      const isPrint = this.route.hash.includes('#print');
      const isPrintEmail = this.route.hash.includes('#print-email');
      if (isPrint) {
        this.setPrintMode(isPrintEmail);
      }
    },
    switchToPrintMode() {
      if (this.isPrint) {
        loadStyles('/print.css');
      }
      if (this.isPrintEmail) {
        document.body.classList.add('print-email');
      }
    },
    updateTemplate() {
      const { template } = qs.parse(window.location.search, { ignoreQueryPrefix: true });
      if (template) {
        this.setTemplate(template);
      } else {
        switch (window.location.hostname) {
          case 'lloyds.bi.freedompay.com':
            this.setTemplate(TEMPLATE_LLOYDS);
            break;
          default:
            this.setTemplate(TEMPLATE_FREEDOMPAY);
            break;
        }
      }
    },
    updateIndustry() {
      const { industry } = qs.parse(window.location.search, { ignoreQueryPrefix: true });
      if (industry) {
        this.setIndustry(industry);
      }
    },
    updateTemplateStyles() {
      // eslint-disable-next-line
      const { document_title: title } = this.copy;

      loadStyles(`${process.env.VUE_APP_THEME_FOLDER || ''}/${this.template}/styles.css`);
      setFavicon(`${process.env.VUE_APP_THEME_FOLDER || ''}/${this.template}/favicon.png`);
      setTitle(title);
    },
    ...mapActions([
      'beforeLoad',
      'setUser',
      'setLocale',
      'setTemplate',
      'setIndustry',
      'setPrintMode',
      'closeToast',
      'hideProgressNotification',
      'getTemplate',
    ]),
  },
};
</script>

<style scoped lang="scss" src="../styles/layouts/BasicLayout.scss" />
