import fetch from 'isomorphic-fetch';
import { authenticationFailed, authHeaders } from '@/services/request-helpers';
import { relogin } from '@/services/api/auth';
import { getSession, setSession } from '@/services/storage';

export const headers = (includeAuth = true) => ({
  'Content-Type': 'application/json',
  ...includeAuth ? authHeaders() : {},
});

const request = async (url, options) => {
  let response = await fetch(url, { headers: headers(), ...options });
  if (!response.ok) {
    if ([401, 403].includes(response.status)) {
      if (getSession() && !getSession().fpSession) {
        try {
          const session = await relogin({ token: getSession().token, refreshToken: getSession().refresh_token });
          setSession(session);
          response = await fetch(url, {
            ...options,
            headers: { ...(options.headers || {}), ...authHeaders() },
          });
        } catch {
          authenticationFailed();
        }
      } else {
        authenticationFailed();
      }
    } else {
      throw response;
    }
    throw response;
  }
  return response;
};

const MAX_REQUESTS_COUNT = 2;
let currentRequestsCount = 0;
const requestsQueue = [];

const runNextRequest = () => {
  if (requestsQueue.length && currentRequestsCount < MAX_REQUESTS_COUNT) {
    requestsQueue.shift()();
    currentRequestsCount += 1;
  }
};

const requestQueue = async (url, options) => {
  return new Promise((resolve, reject) => {
    requestsQueue.push(async () => {
      try {
        const response = await request(url, options);
        resolve(response);
      } catch (e) {
        reject(e);
      } finally {
        currentRequestsCount -= 1;
        runNextRequest();
      }
    });
    runNextRequest();
  });
};

const jsonRequest = async (url, options) => {
  const response = await requestQueue(url, { headers: headers(), ...options });
  return response.json();
};

export const postJson = (url, body = {}, options = {}) => {
  return jsonRequest(url, {
    method: 'POST',
    body: JSON.stringify(body),
    ...options,
  });
};

export const post = (url, options = {}) => {
  return requestQueue(url, {
    method: 'POST',
    ...options,
  });
};

export const putJson = async (url, body = {}, options = {}) => {
  return jsonRequest(url, {
    method: 'PUT',
    body: JSON.stringify(body),
    ...options,
  });
};

export const patchJson = async (url, body = {}, options = {}) => {
  return jsonRequest(url, {
    method: 'PATCH',
    body: JSON.stringify(body),
    ...options,
  });
};

export const getJson = async (url, options = {}) => {
  return jsonRequest(url, options);
};

export const deleteJson = async (url, options = {}) => {
  return fetch(url, {
    method: 'DELETE',
    headers: headers(),
    ...options,
  });
};
