import { MIN_DATE } from '@freedompaybi/time-request';
import moment from 'moment';
import i18next from '@/plugins/i18n';

const $t = i18next.t.bind(i18next);

export const allowedRangeKeys = ['from', 'to', 'last', 'days', 'weeks', 'months', 'quarters', 'years'];
export const dateFormat = 'MMM D, YYYY';
export const inputDateFormat = 'MM/DD/YYYY';
export const innerDateFormat = 'YYYY-MM-DD';
export const urlDateFormat = 'YYYY-MM-DD';

export const TAB_BETWEEN = 'between';
export const TAB_SINCE = 'since';
export const TAB_LAST = 'last';

export const linkGroups = {
  [TAB_BETWEEN]: [
    [
      { label: $t('times.today'), value: { from: moment().toDate(), to: moment().toDate() } },
      { label: $t('times.yesterday'), value: { from: moment().subtract(1, 'days').toDate(), to: moment().subtract(1, 'days').toDate() } },
    ],
    [
      { label: $t('times.this_week'), value: { from: moment().startOf('week').toDate(), to: moment().endOf('week').toDate() } },
      { label: $t('times.this_month'), value: { from: moment().startOf('month').toDate(), to: moment().endOf('month').toDate() } },
      { label: $t('times.this_quarter'), value: { from: moment().startOf('quarter').toDate(), to: moment().endOf('quarter').toDate() } },
      { label: $t('times.this_year'), value: { from: moment().startOf('year').toDate(), to: moment().endOf('year').toDate() } },
    ],
    [
      {
        label: $t('times.last_week'),
        value: {
          from: moment().subtract(1, 'week').startOf('week').toDate(),
          to: moment().subtract(1, 'week').endOf('week').toDate(),
        },
      },
      {
        label: $t('times.last_month'),
        value: {
          from: moment().subtract(1, 'month').startOf('month').toDate(),
          to: moment().subtract(1, 'month').endOf('month').toDate(),
        },
      },
      {
        label: $t('times.last_quarter'),
        value: {
          from: moment().subtract(1, 'quarter').startOf('quarter').toDate(),
          to: moment().subtract(1, 'quarter').endOf('quarter').toDate(),
        },
      },
      {
        label: $t('times.last_year'),
        value: {
          from: moment().subtract(1, 'year').startOf('year').toDate(),
          to: moment().subtract(1, 'year').endOf('year').toDate(),
        },
      },
    ],
  ],
  [TAB_SINCE]: [
    [
      { label: $t('times.all_time'), value: { from: MIN_DATE } },
    ],
    [
      { label: $t('times.beginning_of_this_week'), value: { from: moment().startOf('week').toDate() } },
      { label: $t('times.beginning_of_this_month'), value: { from: moment().startOf('month').toDate() } },
      { label: $t('times.beginning_of_this_quarter'), value: { from: moment().startOf('quarter').toDate() } },
      { label: $t('times.beginning_of_this_year'), value: { from: moment().startOf('year').toDate() } },
    ],
  ],
  [TAB_LAST]: [
    [
      { label: $t('times.last_n_days', { n: 7 }), value: { days: 7, last: true } },
      { label: $t('times.last_n_days', { n: 30 }), value: { days: 30, last: true } },
      { label: $t('times.last_n_days', { n: 60 }), value: { days: 60, last: true } },
      { label: $t('times.last_n_days', { n: 90 }), value: { days: 90, last: true } },
    ],
    [
      { label: $t('times.last_n_months', { n: 3 }), value: { months: 3, last: true } },
      { label: $t('times.last_n_months', { n: 6 }), value: { months: 6, last: true } },
    ],
    [
      { label: $t('times.last_week'), value: { weeks: 1, last: true } },
      { label: $t('times.last_month'), value: { months: 1, last: true } },
      { label: $t('times.last_quarter'), value: { quarters: 1, last: true } },
      { label: $t('times.last_year'), value: { years: 1, last: true } },
    ],
  ],
};
