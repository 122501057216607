<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="6"
      y="6"
      width="12"
      height="12"
      rx="1"
      stroke="black"
      stroke-width="2"
    />
    <rect
      x="6"
      y="6"
      width="12"
      height="3"
      rx="1"
      stroke="black"
      stroke-width="2"
    />
  </svg>
</template>
