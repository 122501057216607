import get from 'lodash/get';
import { getSummaryData, abortAllSummaryData } from '@/services/api/summary';
import { METRIC_TOTAL_SALES, METRIC_TRANSACTIONS, METRIC_AVG_SALES, METRIC_CUSTOMERS, METRIC_INTERCHANGE_FEES } from '@/constants/metrics';
import { DEFAULT_CURRENCY } from '@/constants/currencies';

const getInitialState = () => ({
  metrics: [
    METRIC_TOTAL_SALES,
    METRIC_TRANSACTIONS,
    METRIC_AVG_SALES,
  ],
  summary: [{
    title: METRIC_TOTAL_SALES,
    value: 0,
    trend: null,
    currency: DEFAULT_CURRENCY,
  }, {
    title: METRIC_TRANSACTIONS,
    value: 0,
    trend: null,
  }, {
    title: METRIC_AVG_SALES,
    value: 0,
    trend: null,
    currency: DEFAULT_CURRENCY,
  }, {
    title: METRIC_CUSTOMERS,
    value: 0,
    trend: null,
  }, {
    title: METRIC_INTERCHANGE_FEES,
    value: 0,
    trend: null,
    currency: DEFAULT_CURRENCY,
  }],
});

const state = getInitialState();

const mutations = {
  SET_SUMMARY(state, summary) {
    const totalSales = get(summary, 'total_sales', 0);
    const transactions = totalSales ? get(summary, 'count', 0) : 0;
    const avgSale = get(summary, 'avg_sale', transactions && totalSales ? totalSales / transactions : 0);
    const customers = get(summary, 'customers', 0);
    const intFee = get(summary, 'interchange_fees', 0);
    const currency = get(summary, 'currency', DEFAULT_CURRENCY);

    state.summary = [{
      currency,
      title: METRIC_TOTAL_SALES,
      value: totalSales,
      trend: get(summary, 'trend.percentage_increase.total_sales', null),
    }, {
      title: METRIC_TRANSACTIONS,
      value: transactions,
      trend: get(summary, 'trend.percentage_increase.transactions', null),
    }, {
      currency,
      title: METRIC_AVG_SALES,
      value: avgSale,
      trend: get(summary, 'trend.percentage_increase.avg_sale', null),
    }, {
      title: METRIC_CUSTOMERS,
      value: customers,
      trend: get(summary, 'trend.percentage_increase.customers', null),
    }, {
      currency,
      title: METRIC_INTERCHANGE_FEES,
      value: intFee,
      trend: get(summary, 'trend.percentage_increase.interchange_fees', null),
    }];
  },
  SET_SUMMARY_METRICS(state, metrics) {
    state.metrics = metrics;
  },
  RESET_STATE(state) {
    Object.assign(state, getInitialState());
  },
};
const actions = {
  async fetchReportSummary({ commit }, { segment, geo, org, filter, range, trend, currency }) {
    abortAllSummaryData();
    const summary = await getSummaryData({ segment, geo, org, filter, range, trend, currency });
    commit('SET_SUMMARY', summary);
  },
  setSummaryMetrics({ commit }, metrics) {
    commit('SET_SUMMARY_METRICS', metrics);
  },
  resetReportState({ commit }) {
    commit('RESET_STATE');
  },
};
const getters = {
  getReportSummary: ({ summary, metrics }) => summary.filter(({ title }) => metrics.includes(title)),
  getReportSummaryMetrics: ({ metrics }) => metrics,
};

export default {
  state,
  getters,
  actions,
  mutations,
};
