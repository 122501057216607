<template>
  <svg width="28" height="20" viewBox="0 0 28 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.25" y="0.25" width="27.5" height="19.5" rx="1.75" fill="white" stroke="#F5F5F5" stroke-width="0.5" />
    <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="28" height="20">
      <rect x="0.25" y="0.25" width="27.5" height="19.5" rx="1.75" fill="white" stroke="white" stroke-width="0.5" />
    </mask>
    <g mask="url(#mask0)">
      <rect x="20" width="8" height="20" fill="#FF3131" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M0 20H8V0H0V20Z" fill="#FF3131" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M16.1117 9.22157C15.8785 9.45477 15.4845 9.24389 15.5492 8.92049L15.9999 6.66671L14.6666 7.33337L13.9999 5.33337L13.3333 7.33337L11.9999 6.66671L12.4507 8.92049C12.5154 9.24389 12.1213 9.45477 11.8881 9.22157L11.569 8.90241C11.4388 8.77223 11.2277 8.77223 11.0975 8.90241L10.6666 9.33337L9.33325 8.66671L9.99992 10L9.56895 10.431C9.43878 10.5612 9.43878 10.7722 9.56895 10.9024L11.3333 12.6667H13.3333L13.6666 14.6667H14.3333L14.6666 12.6667H16.6666L18.4309 10.9024C18.5611 10.7722 18.5611 10.5612 18.4309 10.431L17.9999 10L18.6666 8.66671L17.3333 9.33337L16.9023 8.90241C16.7721 8.77223 16.5611 8.77223 16.4309 8.90241L16.1117 9.22157Z" fill="#FF3131" />
    </g>
  </svg>
</template>
