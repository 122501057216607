import moment from 'moment';
import jwtDecode from 'jwt-decode';
import get from 'lodash/get';
import { bootstrap as initGtag } from 'vue-gtag';
import { getDefaultRangeAndLastUpdatedAt, getCopy } from '@/services/api/app';
import { getUser, setUser, logout, setPassword, joinTeam } from '@/services/api/user';
import { login, relogin } from '@/services/api/auth';
import { locale, language, currency } from '@/plugins/locale';
import { USD, CAD, EUR, GBP, DEFAULT_CURRENCY } from '@/constants/currencies';
import locationsService from '@/components/LocationsSelector/locationsService';
import productsService from '@/components/ProductSelector/productsService';
import loyaltyService from '@/components/LoyaltySelector/loyaltyService';
import i18next from '@/plugins/i18n';
import { clearSession, getSession, setSession, getTemplate, setTemplate, getIndustry, setIndustry, clearTemplate, getClientId, setClientId, clearClientId, getForecast, setForecast } from '@/services/storage';
import { authenticationFailed } from '@/services/request-helpers';
import { ADMIN } from '@/constants/roles';
import { clearMemoize } from '@/lib/helpers';

const DEFAULT_CLIENT = undefined;

const state = {
  currentUserInfo: null,
  defaultRange: {
    from: null,
    to: null,
    forecastTo: null,
  },
  dataLastUpdated: null,
  showForecast: getForecast(),
  loginDetails: undefined,
  toast: {
    visible: false,
    precontent: '',
    content: '',
    type: 'success',
  },
  progressNotification: {
    visible: false,
    content: '',
  },
  clientId: getClientId() || DEFAULT_CLIENT,
  template: getTemplate(),
  industry: getIndustry(),
  isPrint: false,
  isPrintEmail: false,
  isMenuVisible: false,
  settings: {},
  copy: {},
  templates: [],
};

const mutations = {
  SET_SESSION(state, session) {
    setSession(session);
  },
  CLEAR_SESSION() {
    clearSession();
  },
  SET_DEFAULT_RANGE(state, range) {
    Object.assign(state.defaultRange, range);
  },
  SET_DATA_LAST_UPDATED(state, updatedAt) {
    state.dataLastUpdated = updatedAt;
  },
  SET_TEMPLATE(state, template) {
    state.template = template;
  },
  SET_INDUSTRY(state, industry) {
    state.industry = industry;
  },
  SET_PRINT_MODE(state, isEmail) {
    state.isPrint = true;
    state.isPrintEmail = isEmail;
  },
  SET_MENU_VISIBILITY(state, isVisible) {
    state.isMenuVisible = isVisible;
  },
  setForecasting(state, showForecast) {
    state.showForecast = showForecast;
    setForecast(showForecast);
  },
  closeToast(state) {
    state.toast.visible = false;
  },
  showToast(state, text) {
    const { precontent, content, type = 'success' } = text;
    const capitalizeFirstLetter = (string) => string.charAt(0).toUpperCase() + string.slice(1);

    state.toast.visible = true;
    state.toast.precontent = precontent;
    state.toast.content = capitalizeFirstLetter(content);
    state.toast.type = type;

    setTimeout(() => {
      this.commit('closeToast');
    }, 2500);
  },
  SHOW_PROGRESS_NOTIFICATION(state, text) {
    state.progressNotification.visible = true;
    state.progressNotification.content = text;
    setTimeout(() => {
      this.commit('HIDE_PROGRESS_NOTIFICATION');
    }, 60000);
  },
  HIDE_PROGRESS_NOTIFICATION(state) {
    state.progressNotification.visible = false;
    state.progressNotification.content = '';
  },
  setLoginDetails(state, details) {
    state.loginDetails = details;
    if (details && details.client && details.client.industry) state.industry = details.client.industry;
  },
  SET_CLIENT_ID(state, clientId) {
    state.clientId = clientId || DEFAULT_CLIENT;

    if (clientId) {
      setClientId(clientId);
    } else {
      clearClientId();
    }
  },
  SET_CURRENT_USER_INFO(state, currentUserInfo) {
    state.currentUserInfo = currentUserInfo;
  },
  SET_APP_SETTINGS(state, settings) {
    state.settings = settings;
  },
  SET_TEMPLATE_DEFAULT_SETTINGS(state, templates) {
    state.templates = templates;
  },
  SET_COPY(state, copy) {
    state.copy = copy;
  },
};

const actions = {
  async beforeLoad({ dispatch }) {
    try {
      await dispatch('init');
    } catch (e) {
      // ignore error
    }
  },
  async init({ dispatch, getters }) {
    if (getSession()) {
      dispatch('getCurrentUserInfo');
      await dispatch('getUser');
      await Promise.all([
        dispatch('fetchDefaultRangeAndLastUpdated'),
        dispatch('loadReportsLinks'),
        locationsService.init(),
        getters.isProductAvailable ? productsService.init() : true,
        getters.isLoyaltyAvailable ? loyaltyService.init() : true,
        getters.isCustomMetricsAvailable ? dispatch('fetchMetrics') : true,
      ]);
      if (getters.isSegmentAvailable) {
        dispatch('fetchSegments');
      }
      if (getters.isIntercomEnabled) {
        dispatch('initIntercom');
      }
      if (getters.isGtagEnabled) {
        initGtag();
      }
    } else {
      authenticationFailed();
    }
  },
  async login({ dispatch, commit }, { email, password, client }) {
    const session = await login({ clientId: client, email, password });
    commit('SET_CLIENT_ID', client);
    commit('SET_SESSION', session);
    await dispatch('init');
  },
  async relogin({ dispatch, commit }) {
    if (getSession() && !getSession().fpSession) {
      try {
        const session = await relogin({ token: getSession().token, refreshToken: getSession().refresh_token });
        commit('SET_SESSION', session);
        await dispatch('init');
      } catch (e) {
        commit('CLEAR_SESSION');
        throw e;
      }
    } else {
      throw new Error('relogin - no session');
    }
  },
  async setNewPassword({ dispatch, commit }, { password, userId, client, token }) {
    const session = await setPassword({ password, userId, client, token });
    commit('SET_SESSION', session);
    await dispatch('init');
  },
  async joinTeam({ dispatch, commit }, { client, email, password, name, acceptToken }) {
    const session = await joinTeam({ client, email, password, name, acceptToken });
    commit('SET_CLIENT_ID', client);
    commit('SET_SESSION', session);
    await dispatch('init');
  },
  async getTemplate({ commit }) {
    const copy = await getCopy(state.template);
    commit('SET_COPY', copy);
  },
  getCurrentUserInfo({ commit }) {
    if (getSession()) {
      const { token } = getSession();

      if (token) {
        const { data, roles } = jwtDecode(token);
        if (data) {
          const [userId, clientId, sessionId] = data.split('/');

          commit('SET_CURRENT_USER_INFO', {
            userId,
            clientId,
            sessionId,
            roles: (roles || []).reduce((o, key) => ({ ...o, [key]: true }), {}),
          });
          commit('SET_CLIENT_ID', clientId);
        }
      }
    } else {
      commit('SET_CURRENT_USER_INFO', null);
    }
  },
  async fetchDefaultRangeAndLastUpdated({ commit }) {
    const { updatedAt, from, to } = await getDefaultRangeAndLastUpdatedAt();
    const forecastTo = moment(to).add(11, 'month').startOf('month').toDate();
    commit('SET_DEFAULT_RANGE', { from: moment(from).toDate(), to: moment(to).toDate(), forecastTo });
    commit('SET_DATA_LAST_UPDATED', updatedAt);
  },
  setTemplate({ commit }, template) {
    commit('SET_TEMPLATE', template);

    if (template) {
      document.body.classList.add(`template-${template}`);
      setTemplate(template);
    } else {
      clearTemplate();
    }
  },
  setIndustry({ commit }, industry) {
    commit('SET_INDUSTRY', industry);
    setIndustry(industry);
  },
  setReportLinks({ commit }, { salesReports, comparisonReports, guestAnalysisReports }) {
    commit('SET_REPORT_LINKS', { salesReports, comparisonReports, guestAnalysisReports });
  },
  showToast({ commit }, text) {
    commit('showToast', text);
  },
  closeToast({ commit }) {
    commit('closeToast');
  },
  showProgressNotification({ commit }, text) {
    commit('SHOW_PROGRESS_NOTIFICATION', text);
  },
  hideProgressNotification({ commit }) {
    commit('HIDE_PROGRESS_NOTIFICATION');
  },
  setAppForecast({ commit }, forecast) {
    commit('setForecasting', forecast);
  },
  showMenu({ commit }) {
    commit('SET_MENU_VISIBILITY', true);
  },
  hideMenu({ commit }) {
    commit('SET_MENU_VISIBILITY', false);
  },
  initIntercom({ state, getters }) {
    const email = get(getters.loginDetails, 'email', '');

    if (email && !email.includes('test.bi.freedompay.com') && !getters.isPrint && !getters.isPrintEmail) {
      window.Intercom('boot', {
        user_id: state.loginDetails.id,
        name: `${state.loginDetails.name.first} ${state.loginDetails.name.last}`,
        email: state.loginDetails.email,
        env: process.env.VUE_APP_VERISION,
        language_override: language,
        company: {
          company_id: getters.clientId,
          name: getters.clientId,
        },
      });
    }
  },
  updateIntercom({ state }) {
    window.Intercom('update', {
      name: `${state.loginDetails.name.first} ${state.loginDetails.name.last}`,
    });
  },
  clearIntercom() {
    window.Intercom('shutdown');
  },
  async getUser({ commit }) {
    const details = await getUser();
    commit('setLoginDetails', details);
  },
  async setUser({ commit, dispatch, getters, state }, { firstName, lastName, settings, currentPassword, newPassword }) {
    const isLanguageChanged = get(settings, 'language') && get(state, 'loginDetails.settings.language') !== settings.language;
    const details = await setUser(getters.currentUserId, {
      firstName,
      lastName,
      settings: {
        ...state.loginDetails.settings,
        ...settings,
      },
      currentPassword,
      newPassword,
    });

    commit('setLoginDetails', details);
    if (isLanguageChanged) {
      dispatch('setLocale', settings.language);
    }
    if (getters.isIntercomEnabled) {
      dispatch('updateIntercom');
    }
  },
  setLocale({ getters }) {
    i18next.changeLanguage(getters.getLocale);
  },
  setPrintMode({ commit }, isPrintEmail) {
    commit('SET_PRINT_MODE', isPrintEmail);
  },
  async logout({ commit, getters, dispatch }) {
    clearMemoize();
    await logout();
    if (getters.isIntercomEnabled) {
      dispatch('clearIntercom');
    }
    commit('setLoginDetails', undefined);
    commit('CLEAR_SESSION');
    dispatch('getCurrentUserInfo');
  },
};

const getters = {
  currentUserId: ({ currentUserInfo }) => currentUserInfo ? currentUserInfo.userId : null,
  userRoles: ({ currentUserInfo }) => currentUserInfo ? currentUserInfo.roles : {},
  showForecast: ({ showForecast }) => showForecast,
  dataLastUpdated: ({ dataLastUpdated }) => dataLastUpdated,
  templates: ({ templates }) => templates,
  loginDetails: ({ loginDetails }) => loginDetails,
  isLoggedIn: ({ loginDetails }) => !!loginDetails,
  toast: ({ toast }) => toast,
  getProgressNotification: ({ progressNotification }) => progressNotification,
  template: ({ template }) => template,
  copy: ({ copy }) => copy,
  getIndustry: ({ industry }) => industry,
  clientId: ({ clientId }) => clientId,
  getDefaultRange: ({ defaultRange }) => defaultRange.from && defaultRange.to ? defaultRange : null,
  isPrint: ({ isPrint }) => isPrint,
  isPrintEmail: ({ isPrintEmail }) => isPrintEmail,
  isMenuVisible: ({ isMenuVisible }) => isMenuVisible,
  getCurrency: ({ loginDetails }) => {
    const defaultCurrency = [USD, CAD, EUR, GBP].includes(currency) ? currency : DEFAULT_CURRENCY;
    return get(loginDetails, 'settings.currency', defaultCurrency);
  },
  getConvertCurrency: ({ loginDetails }) => get(loginDetails, 'settings.convert_currency', false),
  getLocale: ({ loginDetails }) => get(loginDetails, 'settings.language', locale),
  isAdmin: ({ loginDetails }) => get(loginDetails, 'role') === ADMIN,
  isProductAvailable: ({ settings }) => process.env.VUE_APP_PRODUCT === 'true' && get(settings, 'application.isProductAvailable', true),
  isLoyaltyAvailable: ({ settings }) => process.env.VUE_APP_LOYALTY === 'true' && get(settings, 'application.isLoyaltyAvailable', true),
  isCustomMetricsAvailable: ({ settings }) => process.env.VUE_APP_CUSTOM_METRICS === 'true' && get(settings, 'application.isCustomMetricsAvailable', true),
  isSegmentAvailable: ({ settings }) => process.env.VUE_APP_SEGMENT === 'true' && get(settings, 'application.isSegmentAvailable', true),
  isSalesAvailable: ({ settings }) => process.env.VUE_APP_SALES === 'true' && get(settings, 'application.isSalesAvailable', true),
  isDataAvailable: ({ settings }, { isAdmin }) => process.env.VUE_APP_DATA === 'true' && get(settings, 'application.isDataAvailable', true) && isAdmin,
  isIntercomEnabled: ({ settings, isPrint, isPrintEmail }) => process.env.VUE_APP_INTERCOM === 'true' && get(settings, 'application.isIntercomEnabled', true) && !isPrint && !isPrintEmail,
  isGtagEnabled: ({ isPrint, isPrintEmail }) => process.env.VUE_APP_GA === 'true' && !isPrint && !isPrintEmail,
};

export default {
  state,
  getters,
  actions,
  mutations,
};
