/* eslint-disable class-methods-use-this */
import i18next from 'i18next';
import isArray from 'lodash/isArray';
import isString from 'lodash/isString';
import flatten from 'lodash/flatten';
import find from 'lodash/find';
import values from 'lodash/values';
import compact from 'lodash/compact';
import reduce from 'lodash/reduce';
import isEqual from 'lodash/isEqual';
import without from 'lodash/without';
import omit from 'lodash/omit';
import get from 'lodash/get';
import has from 'lodash/has';
import { memoize } from '@/lib/helpers';
import { getGeoFriendlyName } from '@/lib/i18n-helpers';
import {
  PLACE_TYPE_COUNTRY,
  PLACE_TYPE_STATE,
  PLACE_TYPE_COUNTY,
  PLACE_TYPE_CITY,
  PLACE_TYPE_STORE,
  PLACE_TYPE_ADDRESS,
} from '@/constants/place_types';
import { getPlaces, getOrganizations } from '@/services/api/locations';
import { TYPE_GEO, TYPE_ORG } from '@/components/LocationsSelector/constants';
import {
  ORG_TYPE_ENTERPRISE,
  ORG_TYPE_TIER1,
  ORG_TYPE_TIER2,
  ORG_TYPE_TIER3,
  ORG_TYPE_TIER4,
  ORG_TYPE_STORE,
} from '@/constants/org_types';

const $t = i18next.t.bind(i18next);

class LocationsService {
  constructor() {
    this.places = [];
    this.organizations = null;
    this.isUnknownPresented = false;
    this.isInit = false;
    this.init = memoize(this.init.bind(this));
    this.getPlacesTreeOptions = memoize(this.getPlacesTreeOptions.bind(this));
    this.getOrganizationsTreeOptions = memoize(this.getOrganizationsTreeOptions.bind(this));
    this.unzipPlaces = memoize(this.unzipPlaces.bind(this));
    this.unzipOrganizations = memoize(this.unzipOrganizations.bind(this));
    this.findMultipleChildrenLevel = memoize(this.findMultipleChildrenLevel.bind(this));
  }

  /*
    Method must be executed before call any other helper functions
   */
  async init() {
    const [{ places }, { organizations }] = await Promise.all([getPlaces(), getOrganizations()]);
    this.isUnknownPresented = has(places, '');
    this.places = omit(places, '');
    this.organizations = organizations;
    this.isInit = true;
  }

  /*
    Method takes places object from API and returns the array of tree values
    @returns { array } list of tree values
  */
  getPlacesTreeOptions() {
    const placesLevels = [PLACE_TYPE_COUNTRY, PLACE_TYPE_STATE, PLACE_TYPE_CITY, PLACE_TYPE_STORE];
    const sortByLabel = (option) => option.sort((a, b) => (a.label > b.label) ? 1 : -1);

    function format(key, levelPlaces) {
      const treeOptions = [];
      if (isArray(levelPlaces)) {
        levelPlaces.forEach((store) => {
          treeOptions.push({
            id: [...key, store.id].map((placeValue, i) => {
              return `${placesLevels[i]}=${placeValue}`;
            }).join('&'),
            label: `${store.address} (${store.id})`,
          });
        });
      } else {
        Object.keys(levelPlaces).forEach((place) => {
          const placeType = placesLevels[[...key].length];
          treeOptions.push({
            id: [...key, place].map((placeValue, i) => {
              return `${placesLevels[i]}=${placeValue}`;
            }).join('&'),
            label: getGeoFriendlyName(placeType, place),
            children: sortByLabel(format([...key, place], levelPlaces[place])),
          });
        });
      }
      return treeOptions;
    }

    return [{
      id: '',
      label: $t('segments.all_locations'),
      children: [
        ...format([], this.places),
        ...(this.isUnknownPresented ? [{
          id: 'country=',
          label: $t('locations.unknown'),
        }] : []),
      ],
    }];
  }

  /*
    Method takes array of organizations from API and returns the array of tree values
    @params { integer } levels limit; infinity by default
    @returns { array } list of tree values
  */
  getOrganizationsTreeOptions(levelsLimit = Infinity) {
    const getTreeOptions = (organizations, idPrefix, level) => {
      return organizations.map((org) => {
        const id = [...idPrefix, org.id];
        return {
          ...org,
          id: `/${id.join('/')}`,
          label: org.name,
          ...(org.children && level < levelsLimit ? { children: getTreeOptions(org.children, id, level + 1) } : { children: undefined }),
        };
      }).sort((a, b) => a.label > b.label ? 1 : -1);
    };
    return getTreeOptions(this.organizations, [], 0);
  }

  /*
    Method takes places object from API and returns the array of stores which include all address details
    @returns { array } list of all stores with all address details
  */
  unzipPlaces() {
    const unzip = (place) => {
      if (isArray(place)) {
        return place;
      }

      return flatten(values(place).map((nestedPlace) => flatten(unzip(nestedPlace))));
    };

    return unzip(this.places);
  }

  /*
    Method takes organizations tree values and returns the flatten array with id and label of store
    @param { array } organizations tree values
    @returns { array } list of all organizations in flatten array { id: ..., label: ... }
  */
  unzipOrganizations() {
    const treeOptions = this.getOrganizationsTreeOptions();
    const unzip = (orgTreeOptions) => {
      return orgTreeOptions.reduce((orgs, orgTreeOption) => {
        orgs.push({ id: orgTreeOption.id, label: orgTreeOption.label });
        if (orgTreeOption.children) {
          return [...orgs, ...unzip(orgTreeOption.children)];
        }
        return orgs;
      }, []);
    };

    return unzip(treeOptions);
  }

  /*
    Method takes old geo format and return new one. If it gets new format it returns it as is
    @param { string | array } new or old geo format (['country=CA&state=ON&city=Toronto', 'country=US"'], 'country=US,state=AB,CA')
    @returns { array } new geo format like ['country=CA&state=ON&city=Toronto', 'country=US"']
  */
  formatGeo(geo) {
    if (!geo || geo === 'all') {
      return [];
    }
    if (isString(geo)) {
      const flattenPlaces = this.unzipPlaces();
      const levels = compact(geo.split('&')).reduce((accLevels, level) => {
        const [placeType, placeValues] = level.split('=');
        return {
          ...accLevels,
          [placeType]: placeValues.split(','),
        };
      }, {});
      return reduce(levels, (accGeo, placeValues, placeType) => {
        let formattedGeo = [];
        switch (true) {
          case placeType === PLACE_TYPE_COUNTRY:
            if (isEqual(placeValues.sort(), Object.keys(this.places).sort())) {
              formattedGeo = [];
            } else {
              formattedGeo = placeValues.map((country) => `${PLACE_TYPE_COUNTRY}=${country}`);
            }
            break;
          case placeType === PLACE_TYPE_STATE:
            formattedGeo = placeValues.map((state) => {
              const place = find(flattenPlaces, { state });
              const country = get(place, PLACE_TYPE_COUNTRY, '');
              return [
                `${PLACE_TYPE_COUNTRY}=${country}`,
                `${PLACE_TYPE_STATE}=${state}`,
              ].join('&');
            });
            break;
          case placeType === PLACE_TYPE_CITY:
            formattedGeo = placeValues.map((city) => {
              const place = find(flattenPlaces, ({ city: placeCity }) => {
                return placeCity.toLowerCase() === city.toLowerCase();
              });
              const country = get(place, PLACE_TYPE_COUNTRY, '');
              const state = get(place, PLACE_TYPE_STATE, '');
              return [
                `${PLACE_TYPE_COUNTRY}=${country}`,
                `${PLACE_TYPE_STATE}=${state}`,
                `${PLACE_TYPE_CITY}=${city}`,
              ].join('&');
            });
            break;
          case placeType === PLACE_TYPE_STORE:
            formattedGeo = placeValues.map((storeId) => {
              const place = find(flattenPlaces, { id: storeId });
              const country = get(place, PLACE_TYPE_COUNTRY, '');
              const state = get(place, PLACE_TYPE_STATE, '');
              const city = get(place, PLACE_TYPE_CITY, '');
              return [
                `${PLACE_TYPE_COUNTRY}=${country}`,
                `${PLACE_TYPE_STATE}=${state}`,
                `${PLACE_TYPE_CITY}=${city}`,
                `${PLACE_TYPE_STORE}=${storeId}`,
              ].join('&');
            });
            break;
        }
        return [...accGeo, ...formattedGeo];
      }, []);
    }
    return geo;
  }

  /*
    Method takes new geo format and returns array of values. It returns undefined if that type was not found in geo
    @param { array } new geo format (['country=CA&state=ON&city=Toronto', 'country=US'])
    @param { string } place type
    @returns { array } places names like ['CA', 'US', undefined]
   */
  getGeoValueByType(geoTags, type) {
    return geoTags.map((geoTag) => {
      const geoParts = geoTag.split('&');
      return geoParts.reduce((name, geoPart) => {
        const [placeType, placeValue] = geoPart.split('=');
        if (type === PLACE_TYPE_ADDRESS && placeType === PLACE_TYPE_STORE) {
          const place = find(this.unzipPlaces(), { id: placeValue });
          return place ? place[PLACE_TYPE_ADDRESS] : '';
        }

        return placeType === type ? placeValue : name;
      }, undefined);
    });
  }

  /*
  Method takes org and type and returns value for this type. It returns undefined if that type was not found in org
  @param { string } org format ('/1/12/123')
  @param { string } org type
  @returns { string } org value like '12' or '123'
 */
  getOrgValueByType(orgTag, type) {
    const orgTypes = [ORG_TYPE_ENTERPRISE, ORG_TYPE_TIER1, ORG_TYPE_TIER2, ORG_TYPE_TIER3, ORG_TYPE_TIER4, PLACE_TYPE_STORE];
    const [, ...orgTagValues] = orgTag.split('/');
    const orgTypeIndex = orgTypes.findIndex((orgType) => orgType === type);
    return orgTagValues[orgTypeIndex];
  }

  /*
    Method takes old or new geo format and returns treeselect value
    @param { array } new geo format (['country=CA&state=ON&city=Toronto', 'country=US'])
    @returns { array } treeselect value like ['country=CA,state=ON,city=Toronto', 'country=US']
  */
  geoToTreeValue(geo) {
    return !geo || geo === 'all' || (isArray(geo) && geo.length === 0) ? [''] : geo;
  }

  /*
    Method takes treeselect value and returns new geo format
    @param { array } treeselect value like ['country=CA,state=ON,city=Toronto', 'country=US']
    @returns { array } new geo format (['country=CA&state=ON&city=Toronto', 'country=US'])
  */
  treeValueToGeo(treeValue) {
    return without(treeValue, '');
  }

  /*
    Method takes treeselect options and returns the level where presented multiple options
    Note: we ignore store level for organizations
    @param { type } GEO or ORG type
    @param { number } the first level that contains multiple options
   */
  findMultipleChildrenLevel(type) {
    const topOptions = type === TYPE_ORG ? this.getOrganizationsTreeOptions() : this.getPlacesTreeOptions();
    const calculateLevel = (level, options) => {
      if (!options || options.length > 1) {
        return level;
      }
      return calculateLevel(level + 1, options[0].children);
    };
    const level = calculateLevel(0, topOptions);
    return type === TYPE_ORG ? Math.min(level, 4) : level;
  }

  /*
    Method returns geo values from the level, where are more that one item available
    @return { array } geo values
   */
  getTopRegions() {
    const topOptions = this.getPlacesTreeOptions();
    const calculateLevel = (level, options) => {
      if (level === 4 || !isArray(options) || options.length > 1) {
        return (options || []).map(({ id }) => id).filter((id) => id !== 'country=');
      }
      return calculateLevel(level + 1, options[0].children);
    };
    return calculateLevel(0, topOptions);
  }

  getMostSpecificGeo(geoTag) {
    const mostSpecificGeo = geoTag.split('&').pop();
    const [placeType, placeValue] = mostSpecificGeo.split('=');
    return {
      scope: placeType,
      value: placeValue,
    };
  }

  /*
    Method takes new geo format and returns localized label
    If there is more than one value, we returns codes without localization
    @param { array } new geo format (['country=CA&state=ON&city=Toronto', 'country=US'])
    @returns { string } localized label
  */
  getGeoLabel(geo) {
    if (isArray(geo) && geo.length) {
      return geo.map((geoTag) => {
        const { scope, value } = this.getMostSpecificGeo(geoTag);
        if (scope === PLACE_TYPE_STORE) {
          return this.getGeoValueByType([geoTag], PLACE_TYPE_ADDRESS)[0] || value;
        }
        return getGeoFriendlyName(scope, value);
      }).join(', ');
    }
    return $t('segments.all_locations');
  }

  /*
    Method takes list of organizations ids and returns label
    If it's empty array function should return top level labels
    @param { array } list of organizations ids
    @returns { string } label
  */
  getOrgLabel(orgIds) {
    const treeOptions = this.getOrganizationsTreeOptions();
    if (isArray(orgIds) && orgIds.length) {
      const flattenOrganizations = this.unzipOrganizations();
      return orgIds.map((orgId) => {
        const org = flattenOrganizations.find((flattenOrg) => flattenOrg.id === orgId);
        return get(org, 'label', $t('locations.n/a'));
      }).join(', ');
    }
    return treeOptions.map((option) => option.label).join(', ');
  }

  /*
    Method takes list of geo and org ids and returns label depends on values
    One of the array must be empty
    @param { array | undefined } new geo format (['country=CA&state=ON&city=Toronto', 'country=US'])
    @param { array | undefined } list of organizations ids
    @returns { string } label
   */
  getLabel(geoIds, orgIds) {
    if (isArray(orgIds) && orgIds.length) {
      return this.getOrgLabel(orgIds);
    }
    return this.getGeoLabel(geoIds);
  }

  /*
    Method returns one of scopes type depends on provided scope
    @param { string } geo or org scope
    @return { string } geo or org type
   */
  getScopeTypeByScope(scope) {
    if ([PLACE_TYPE_COUNTRY, PLACE_TYPE_STATE, PLACE_TYPE_COUNTY, PLACE_TYPE_CITY, PLACE_TYPE_STORE].includes(scope)) {
      return TYPE_GEO;
    }
    if ([ORG_TYPE_ENTERPRISE, ORG_TYPE_TIER1, ORG_TYPE_TIER2, ORG_TYPE_TIER3, ORG_TYPE_TIER4, ORG_TYPE_STORE].includes(scope)) {
      return TYPE_ORG;
    }
    return undefined;
  }
}

export default new LocationsService();
