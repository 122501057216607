<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19.2947 12.7053C19.6842 12.3158 19.6842 11.6842 19.2947 11.2947V11.2947C18.9054 10.9054 18.2743 10.9051 17.8847 11.2941L13 16.17V5C13 4.44771 12.5523 4 12 4V4C11.4477 4 11 4.44772 11 5V16.17L6.12711 11.2884C5.73668 10.8973 5.10045 10.8995 4.70968 11.2903V11.2903C4.31916 11.6808 4.31658 12.3166 4.70711 12.7071L11.2929 19.2929C11.6834 19.6834 12.3166 19.6834 12.7071 19.2929L19.2947 12.7053Z"
      :fill="color"
    />
  </svg>
</template>

<script>
export default {
  props: {
    disabled: Boolean,
  },
  computed: {
    color() {
      return this.disabled ? '#8d8f93' : '#000';
    },
  },
};
</script>
