import fetch from 'isomorphic-fetch';
import { LOGIN_PATH, RELOGIN_PATH } from '@/constants/endpoints';

export const login = async ({ clientId, email, password }) => {
  const post = await fetch(`${LOGIN_PATH}?client=${clientId}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      username: email,
      password,
    }),
  });

  return post.json();
};

export const relogin = async ({ token, refreshToken }) => {
  const response = await fetch(`${RELOGIN_PATH}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      token,
      refresh_token: refreshToken,
    }),
  });
  if (response.status >= 400) {
    throw new Error('relogin error');
  } else {
    return response.json();
  }
};
