import { getSession, getTemplate, isClassicLoginFlag, setPathBeforeLogin } from '@/services/storage';
import { TEMPLATE_LLOYDS, TEMPLATE_FREEDOMPAY } from '@/constants/templates';

export function authenticationFailed() {
  const template = getTemplate();

  if (window.location.pathname !== '/login') {
    setPathBeforeLogin(`${window.location.pathname}${window.location.search}`);
  }

  if ([TEMPLATE_LLOYDS, TEMPLATE_FREEDOMPAY].includes(template) && !isClassicLoginFlag()) {
    window.location = '/sso/freedompay';
  } else if (window.location.pathname !== '/login') {
    window.location = '/login';
  }
}

export const authHeaders = () => {
  const session = getSession();
  return session ? { Authorization: `Bearer ${session.token}` } : {};
};
