import { memoize } from '@/lib/helpers';
import { LAST_UPDATED_AT_PATH, TEMPLATE_PATH } from '@/constants/endpoints';
import { getJson } from '../request';

const getDefaultRangeAndLastUpdatedAt = memoize(() => {
  return getJson(LAST_UPDATED_AT_PATH);
});

const getCopy = memoize((template) => {
  try {
    return getJson(`${TEMPLATE_PATH}/${template}/copy.json`);
  } catch (err) {
    console.error('AP-A001 ', err);
    return {};
  }
});

export {
  getDefaultRangeAndLastUpdatedAt,
  getCopy,
};
