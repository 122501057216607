<template>
  <svg
    width="34"
    height="34"
    viewBox="0 0 34 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.1135 20.8275L20.8274 13.1136"
      :stroke="color"
      stroke-width="2"
    />
    <path
      d="M13.1135 13.1136L20.8274 20.8275"
      :stroke="color"
      stroke-width="2"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M24.0416 24.0416C27.9468 20.1364 27.9468 13.8047 24.0416 9.89949C20.1363 5.99425 13.8047 5.99425 9.89944 9.89949C5.99419 13.8047 5.99419 20.1364 9.89944 24.0416C13.8047 27.9469 20.1363 27.9469 24.0416 24.0416Z"
      :stroke="color"
      stroke-width="2"
    />
  </svg>
</template>

<script>
export default {
  name: 'RemoveCircleIcon',
  props: {
    color: {
      type: String,
      default: '#DBDBDB',
    },
  },
};
</script>
