import { SUBSCRIPTION_PATH } from '@/constants/endpoints';
import { getJson, postJson, putJson, deleteJson } from '../request';

export const getSubscriptions = () => {
  return getJson(SUBSCRIPTION_PATH);
};

export const getSubscription = (id) => {
  return getJson(`${SUBSCRIPTION_PATH}/${id}`);
};

export const addSubscription = (params) => {
  return postJson(SUBSCRIPTION_PATH, params);
};

export const editSubscription = (id, params) => {
  return putJson(`${SUBSCRIPTION_PATH}/${id}`, params);
};

export const unsubscribe = (id) => {
  return deleteJson(`${SUBSCRIPTION_PATH}/${id}`);
};

export const unsubscribeEmail = (schedulerId, unsubscribeKey) => {
  return deleteJson(`${SUBSCRIPTION_PATH}/${schedulerId}/unsubscribe/${unsubscribeKey}`);
};
