<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M12 4C7.58201 4 4 7.58201 4 12C4 16.418 7.58201 20 12 20C16.418 20 20 16.418 20 12C20 7.58201 16.418 4 12 4ZM13.6667 16.3995C13.254 16.5608 12.9259 16.6852 12.6799 16.7698C12.4339 16.8545 12.1508 16.8968 11.8254 16.8968C11.328 16.8968 10.9392 16.7751 10.6614 16.5317C10.3862 16.291 10.2487 15.9815 10.2487 15.6058C10.2487 15.4603 10.2593 15.3122 10.2804 15.1614C10.2989 15.0079 10.3333 14.8386 10.3783 14.6455L10.8942 12.8254C10.9392 12.6508 10.9788 12.4868 11.0106 12.3307C11.0423 12.1746 11.0556 12.0317 11.0556 11.9021C11.0556 11.672 11.0079 11.5079 10.9127 11.418C10.8148 11.3254 10.6323 11.2804 10.3624 11.2804C10.2302 11.2804 10.0926 11.3016 9.95238 11.3413C9.81217 11.3836 9.69312 11.4233 9.59259 11.4603L9.73016 10.8995C10.0661 10.7619 10.3889 10.6455 10.6984 10.5476C11.0053 10.4497 11.2989 10.3995 11.5714 10.3995C12.0661 10.3995 12.4497 10.5212 12.7169 10.7593C12.9841 10.9974 13.119 11.3095 13.119 11.6905C13.119 11.7698 13.1111 11.9101 13.0926 12.1085C13.0741 12.3069 13.0397 12.4894 12.9894 12.6587L12.4762 14.4735C12.4339 14.619 12.3968 14.7857 12.3624 14.9709C12.3307 15.1561 12.3148 15.2989 12.3148 15.3942C12.3148 15.6349 12.3677 15.8016 12.4762 15.8889C12.582 15.9762 12.7698 16.0185 13.037 16.0185C13.1614 16.0185 13.3016 15.9974 13.4603 15.955C13.6164 15.9101 13.7302 15.873 13.8016 15.8386L13.6667 16.3995ZM12.7116 9.36508C13.0476 9.36508 13.336 9.25397 13.5741 9.03175C13.8148 8.80952 13.9339 8.53968 13.9339 8.22487C13.9339 7.91005 13.8148 7.63757 13.5741 7.4127C13.336 7.18783 13.0476 7.07672 12.7116 7.07672C12.3757 7.07672 12.0847 7.18783 11.8439 7.4127C11.6058 7.63757 11.4841 7.91005 11.4841 8.22487C11.4841 8.53968 11.6032 8.80952 11.8439 9.03175C12.0847 9.25397 12.3757 9.36508 12.7116 9.36508Z"
          :fill="color" />
  </svg>
</template>

<script>
export default {
  props: {
    disabled: Boolean,
  },
  computed: {
    color() {
      return this.disabled ? '#8d8f93' : '#000';
    },
  },
};
</script>
