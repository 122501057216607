<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path :fill="color" :stroke="color" d="M22.917 4.49224C22.7648 4.18883 22.5365 4.03713 22.2321 4.03713H19.9491V1.76159C19.9491 1.45818 19.7969 1.15478 19.4925 1.07892C19.1881 0.927222 18.8837 1.00307 18.6554 1.23063L16.829 3.05106C15.307 2.2167 13.4805 1.76159 11.6541 1.76159C5.79435 1.76159 1 6.54023 1 12.3808C1 18.2214 5.79435 23 11.6541 23C17.5139 23 22.3082 18.2214 22.3082 12.3808C22.3082 10.4845 21.8516 8.73992 21.0145 7.22289L22.8409 5.40246C22.9931 5.09905 23.0692 4.79565 22.917 4.49224ZM19.1881 5.55416H20.4057L18.8837 7.07119H16.9051V5.09905L18.4271 3.58202V4.79565C18.4271 5.25076 18.7315 5.55416 19.1881 5.55416ZM15.3831 12.3808C15.3831 14.5046 13.7088 16.1734 11.578 16.1734C9.44719 16.1734 7.77297 14.5046 7.77297 12.3808C7.77297 10.257 9.44719 8.58822 11.578 8.58822C12.339 8.58822 13.1 8.81578 13.7088 9.19503L11.0453 11.8498C10.7409 12.1532 10.7409 12.6083 11.0453 12.9118C11.1975 13.0635 11.4258 13.1393 11.578 13.1393C11.7302 13.1393 11.9585 13.0635 12.1107 12.9118L14.7743 10.257C15.1548 10.8638 15.3831 11.6223 15.3831 12.3808ZM20.7101 12.3808C20.7101 17.387 16.6007 21.483 11.578 21.483C6.55536 21.483 2.44592 17.387 2.44592 12.3808C2.44592 7.3746 6.55536 3.27862 11.578 3.27862C13.0239 3.27862 14.4698 3.58202 15.6875 4.18883L15.6114 4.26469C15.4592 4.41639 15.3831 4.56809 15.3831 4.79565V7.5263L14.7743 8.13311C13.861 7.45045 12.7956 7.07119 11.578 7.07119C8.61008 7.07119 6.25096 9.42259 6.25096 12.3808C6.25096 15.339 8.61008 17.6904 11.578 17.6904C14.5459 17.6904 16.9051 15.339 16.9051 12.3808C16.9051 11.1672 16.5246 10.1053 15.8397 9.19503L16.4485 8.58822H19.1881C19.4164 8.58822 19.5686 8.51237 19.7208 8.36067L19.7969 8.28481C20.4057 9.49844 20.7101 10.9396 20.7101 12.3808Z" stroke-width="0.4" />
  </svg>
</template>

<script>
export default {
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    color() {
      return this.disabled ? '#8d8f93' : 'black';
    },
  },
};
</script>
