import get from 'lodash/get';
import isString from 'lodash/isString';
import capitalize from 'lodash/capitalize';
import without from 'lodash/without';
import keys from 'lodash/keys';
import first from 'lodash/first';
import i18next from '@/plugins/i18n';
import stateNames from '@/assets/geo-names/states.json';
import countiesNames from '@/assets/geo-names/counties.json';
import { ORG_TYPE_ENTERPRISE, ORG_TYPE_STORE, ORG_TYPE_TIER1, ORG_TYPE_TIER2, ORG_TYPE_TIER3, ORG_TYPE_TIER4 } from '@/constants/org_types';
import { PLACE_TYPE_COUNTRY, PLACE_TYPE_COUNTY, PLACE_TYPE_STATE, PLACE_TYPE_STORE } from '@/constants/place_types';
import { language, locale } from '@/plugins/locale';
import { toMoment } from '@/components/DatePicker/helpers';

const $t = i18next.t.bind(i18next);

export function mapGender(gender) {
  switch (gender) {
    case 'M': case 'Male':
      return $t('male');
    case 'F': case 'Female':
      return $t('female');
    default:
      return gender;
  }
}

export function humanizeScope(str) {
  return $t('reports.all_things', {
    thing: $t(str === 'store_id' ? 'store' : str, { count: 2 }),
  });
}

export function friendlyScope(scope) {
  switch (true) {
    case [ORG_TYPE_STORE, PLACE_TYPE_STORE].includes(scope):
      return $t('store');
    case [ORG_TYPE_ENTERPRISE, ORG_TYPE_TIER4, ORG_TYPE_TIER3, ORG_TYPE_TIER2, ORG_TYPE_TIER1].includes(scope):
      return $t(`org.${scope}`);
    default:
      return $t(scope);
  }
}

export function friendlyTimePeriod(time) {
  return $t(`times.${time}-ly`);
}

export function friendlyDay(dayIndex, format = 'long') {
  return capitalize(new Intl.DateTimeFormat(locale, { weekday: format })
    .format(new Date(2019, 6, dayIndex, 1, 0)));
}

export function ordinalNumber(number) {
  const pr = new Intl.PluralRules(locale, { type: 'ordinal' });
  const postfixes = {
    en: {
      one: 'st',
      two: 'nd',
      few: 'rd',
      other: 'th',
    },
    de: {
      other: 'te',
    },
    es: {
      other: '.o',
    },
    fr: {
      one: 'er',
      other: 'ème',
    },
    it: {
      other: '.o',
    },
  };
  const translate = get(postfixes, language, postfixes.en);
  return `${number}${translate[pr.select(number)]}`;
}

export function getGeoFriendlyName(type, geo) {
  let name = geo;

  if (i18next.exists(`geo.${type}.${geo}`)) {
    name = $t(`geo.${type}.${geo}`);
  } else if (geo !== '' && type === PLACE_TYPE_STATE) {
    const state = stateNames.find((item) => item.abbreviation === geo);
    name = state ? state.name : name;
  } else if (geo !== '' && type === PLACE_TYPE_COUNTY) {
    const county = countiesNames.find((item) => item.abbreviation === geo);
    name = county ? county.name : name;
  } else if (geo === '' && type === PLACE_TYPE_COUNTRY) {
    name = $t('locations.unknown');
  }

  return name;
}

export function getRangeFriendlyName(range = {}) {
  const formatDate = (date) => {
    return toMoment(date).format('MMM D, YYYY');
  };
  const lastUnit = first(without(keys(range), 'last'));
  const lastDuration = range[lastUnit];

  switch (true) {
    case !!(range.from && range.to):
      return `${formatDate(range.from)} - ${formatDate(range.to)}`;
    case range.from && !range.to:
      return `${$t('datepicker.since')} ${formatDate(range.from)}`;
    case !range.from && !range.to && lastDuration === 1: {
      const lastUnitSingle = lastUnit.slice(0, -1);
      return $t(`times.last_${lastUnitSingle}`);
    }
    case !range.from && !range.to && lastDuration > 1:
      return `${$t('datepicker.last')} ${lastDuration} ${$t(`times.${lastUnit}`)}`;
    case !range.from && range.to:
      return $t('times.all_time');
    default:
      return '';
  }
}

export function getTranslate(key, fallback) {
  if (key && i18next.exists(key) && isString($t(key, { returnObjects: true }))) {
    return $t(key);
  }
  return fallback || key;
}
