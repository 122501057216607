import moment from 'moment';
import { TEMPLATE_DEFAULT } from '@/constants/templates';

const SESSION_KEY = 'acies-ai-session';
const PATH_BEFORE_LOGIN_KEY = 'acies-ai-path-before-login';
const PATH_CLASSIC_LOGIN_KEY = 'acies-ai-classic-login';
const TEMPLATE_KEY = 'acies-template';
const INDUSTRY_KEY = 'acies-industry';
const CLIENT_ID_KEY = 'acies-client-id';
const FORECAST_KEY = 'aciesForecastingEnabled';

/* Session */

export const getSession = () => {
  if (localStorage.getItem(SESSION_KEY)) {
    return JSON.parse(localStorage.getItem(SESSION_KEY));
  }
  return null;
};
export const setSession = (session) => {
  localStorage.setItem(SESSION_KEY, JSON.stringify(session));
};
export const clearSession = () => {
  localStorage.removeItem(SESSION_KEY);
};

/* Classic login */

export const setClassicLoginFlag = () => {
  localStorage.setItem(PATH_CLASSIC_LOGIN_KEY, '1');
};

export const isClassicLoginFlag = () => {
  return localStorage.getItem(PATH_CLASSIC_LOGIN_KEY) === '1';
};

export const clearClassicLoginFlag = () => {
  localStorage.removeItem(PATH_CLASSIC_LOGIN_KEY);
};

/* Path before login */

export const getPathBeforeLogin = () => {
  const json = localStorage.getItem(PATH_BEFORE_LOGIN_KEY);
  const { path, timestamp } = json ? JSON.parse(json) : {};
  return timestamp && Math.abs(moment.utc().diff(moment.utc(timestamp), 'minutes')) <= 30 ? path : undefined;
};

export const setPathBeforeLogin = (path) => {
  localStorage.setItem(PATH_BEFORE_LOGIN_KEY, JSON.stringify({
    path,
    timestamp: moment.utc().valueOf(),
  }));
};

export const clearPathBeforeLogin = () => {
  localStorage.removeItem(PATH_BEFORE_LOGIN_KEY);
};

/* Template */

export const getTemplate = () => {
  return localStorage.getItem(TEMPLATE_KEY) || TEMPLATE_DEFAULT;
};

export const setTemplate = (template) => {
  localStorage.setItem(TEMPLATE_KEY, template);
};

export const clearTemplate = () => {
  localStorage.removeItem(TEMPLATE_KEY);
};

/* Industry */
export const getIndustry = () => {
  return localStorage.getItem(INDUSTRY_KEY);
};

export const setIndustry = (industry) => {
  localStorage.setItem(INDUSTRY_KEY, industry);
};

/* Client Id */

export const getClientId = () => {
  return localStorage.getItem(CLIENT_ID_KEY);
};

export const setClientId = (clientId) => {
  localStorage.setItem(CLIENT_ID_KEY, clientId);
};

export const clearClientId = () => {
  localStorage.removeItem(CLIENT_ID_KEY);
};

/* Forecast */

export const getForecast = () => {
  return localStorage.getItem(FORECAST_KEY) && JSON.parse(localStorage.getItem(FORECAST_KEY)) === true;
};

export const setForecast = (showForecast) => {
  localStorage.setItem(FORECAST_KEY, showForecast);
};
