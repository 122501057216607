<template>
  <router-link class="logo-link" to="/">
    <img
      v-if="template === TEMPLATE_FREEDOMPAY"
      class="logo"
      :src="require('../../assets/freedompay-logo.svg')"
    />
    <img
      v-else-if="template === TEMPLATE_LLOYDS"
      class="lloyds-logo"
      :src="require('../../assets/lloyds-bank-logo.svg')"
    />
    <img
      v-else
      class="logo"
      :src="`${TEMPLATE_PATH}/${template}/logo.svg`"
    />
  </router-link>
</template>

<script>
import { mapGetters } from 'vuex';
import { TEMPLATE_FREEDOMPAY, TEMPLATE_LLOYDS } from '@/constants/templates';
import { TEMPLATE_PATH } from '@/constants/endpoints';

export default {
  data() {
    return {
      TEMPLATE_FREEDOMPAY,
      TEMPLATE_LLOYDS,
      TEMPLATE_PATH,
    };
  },
  computed: {
    ...mapGetters([
      'template',
    ]),
  },
};
</script>

<style scoped lang="scss" src="../../styles/components/Logo.scss" />
