import last from 'lodash/last';
import { getJson, postJson } from '@/services/request';
import { LIBRARY_PATH, LOYALTY_PATH } from '@/constants/endpoints';

const abortControllers = [];

/*
  loyaltyType available values: program, incentive, pool
 */
export async function getLoyaltyItems(loyaltyType = '') {
  return getJson(`${LOYALTY_PATH}/${loyaltyType}`);
}

export async function getLoyaltyPreview(type, data = {}) {
  return postJson(`${LIBRARY_PATH}/loyalty-report?preview=true`, {
    ...data,
    type,
  });
}

export async function getLoyalty(type, data = {}) {
  abortControllers.push(new AbortController());
  return postJson(`${LIBRARY_PATH}/loyalty-report`, {
    ...data,
    type,
  }, {
    signal: last(abortControllers).signal,
  });
}

export async function getLoyaltyDetails(type, id) {
  return getJson(`${LOYALTY_PATH}/${type}/${id}`);
}

export async function getLoyaltyTree() {
  return getJson(`${LOYALTY_PATH}/tree`);
}

export const abortLatestLoyalty = () => {
  if (abortControllers.length) {
    const abortController = abortControllers.pop();
    abortController.abort();
  }
};

export const abortAllLoyalty = () => {
  while (abortControllers.length) {
    abortLatestLoyalty();
  }
};
