export const API_URL = process.env.VUE_APP_API_URL;
export const DATA_API_URL = process.env.VUE_APP_PROVIDER_API;

export const SALES_PATH = `${DATA_API_URL}/filter`;
export const PLACES_PATH = `${DATA_API_URL}/places`;
export const PRODUCTS_PATH = `${DATA_API_URL}/product`;
export const ORGANIZATIONS_PATH = `${DATA_API_URL}/organizations`;
export const COHORTS_PATH = `${DATA_API_URL}/cohort`;
export const CUSTOMERS_PATH = `${DATA_API_URL}/users`;
export const SUMMARY_PATH = `${DATA_API_URL}/summary`;
export const REPORT_SESSIONS_PATH = `${API_URL}/report/session`;
export const DIMENSION_PATH = `${DATA_API_URL}/dimension`;
export const INSIGHTS_PATH = `${DATA_API_URL}/insight`;
export const PDF_PATH = `${DATA_API_URL}/pdf`;
export const USER_SEARCH_PATH = `${API_URL}/user/search`;
export const REPORT_PATH = `${API_URL}/report`;
export const DASHBOARD_PATH = `${API_URL}/dashboard`;
export const SEGMENT_PATH = `${API_URL}/segment`;
export const LOGIN_PATH = `${API_URL}/login`;
export const RELOGIN_PATH = `${API_URL}/relogin`;
export const LOGOUT_PATH = `${API_URL}/logout`;
export const USER_PATH = `${API_URL}/user`;
export const SEARCH_PATH = `${API_URL}/search`;
export const TYPES_PATH = `${DATA_API_URL}/types`;
export const LAST_UPDATED_AT_PATH = `${DATA_API_URL}/summary/updatedAt`;
export const SUBSCRIPTION_PATH = `${API_URL}/scheduler`;
export const LOYALTY_PATH = `${API_URL}/loyalty`;
export const LIBRARY_PATH = `${DATA_API_URL}/dimension/library`;
export const METRIC_PATH = `${API_URL}/metric`;
export const METRIC_DATA_PATH = `${DATA_API_URL}/metric`;
export const TABLE_DATA_PATH = `${DATA_API_URL}/table`;
export const TEMPLATE_PATH = `${API_URL}/themes`;
