<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.414 13.8983C17.002 13.3628 17.0873 13.5777 17.0873 12.5852C17.0873 11.944 17.4045 12.1793 17.6057 10.9583C17.6841 10.4774 17.7489 10.798 17.9161 10.0272C18.0047 9.62479 17.8547 9.59409 17.8751 9.4031C17.8922 9.21211 17.9092 9.03817 17.9433 8.64595C17.9843 8.15482 17.5477 6.87244 15.9857 6.87244C14.4236 6.87244 13.987 8.15482 14.028 8.64595C14.0587 9.04158 14.0791 9.21211 14.0962 9.4031C14.1132 9.59409 13.9666 9.62479 14.0553 10.0272C14.2224 10.798 14.2872 10.474 14.3656 10.9583C14.5668 12.1793 14.8806 11.9474 14.8806 12.5852C14.8806 13.0422 14.9863 13.2332 14.3724 13.5231C14.5566 13.5879 14.7715 13.6663 15.0307 13.7653C16.8861 14.4712 16.8417 15.1568 16.8895 15.4808C16.9236 15.7025 16.9406 17.4692 16.9474 18.1001H20.2796C20.2796 18.1001 20.2796 15.3546 20.2284 15.034C20.1909 14.7816 19.8533 14.4474 18.414 13.8983Z"
      :fill="color"
    />
    <path
      d="M16.0844 18.1001C16.0844 18.1001 16.0844 17.6192 16.0776 17.0633C16.0708 16.4289 16.0537 15.699 16.0196 15.4808C15.9685 15.1568 15.5319 14.7236 13.68 14.0176C13.4242 13.9221 13.2093 13.8437 13.0217 13.7755C11.886 13.373 11.9679 13.24 11.9679 12.1384C11.9679 11.3096 12.3771 11.8485 12.6363 10.2762C12.7386 9.65547 12.8205 10.0681 13.0354 9.07566C13.1513 8.55725 12.9569 8.51633 12.9808 8.26735C13.0047 8.01838 13.0285 7.79669 13.0695 7.28851C13.124 6.65755 12.5613 5 10.5422 5C8.52315 5 7.96381 6.65414 8.01497 7.28851C8.0559 7.79669 8.07977 8.01838 8.10705 8.26735C8.13093 8.51633 7.93652 8.55384 8.05248 9.07566C8.27076 10.0681 8.35262 9.65547 8.45494 10.2762C8.71414 11.8485 9.12 11.3096 9.12 12.1384C9.12 13.4208 9.23255 13.3253 7.40788 14.0176C5.55593 14.7236 5.11937 15.1568 5.06821 15.4808C5 15.8934 5 18.1001 5 18.1001H10.5422H16.0844Z"
      :fill="color"
    />
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: '#000000',
    },
  },
};
</script>
