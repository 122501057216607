import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import enTranslation from '@/lang/en.json';
import frTranslation from '@/lang/fr.json';
import itTranslation from '@/lang/it.json';
import deTranslation from '@/lang/de.json';
import esTranslation from '@/lang/es.json';
import { createI18n } from './vue-i18next';

i18next
  .use(LanguageDetector)
  .init({
    compatibilityJSON: 'v3',
    fallbackLng: 'en',
    resources: {
      en: {
        translation: enTranslation,
      },
      fr: {
        translation: frTranslation,
      },
      it: {
        translation: itTranslation,
      },
      de: {
        translation: deTranslation,
      },
      es: {
        translation: esTranslation,
      },
    },
  });

export default i18next;
export const VueI18next = createI18n(i18next);
