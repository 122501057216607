<template>
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0)">
      <circle cx="8" cy="8" r="8" fill="#93979C" />
      <path d="M12.9336 6.0918C12.9336 7.12305 12.6553 7.9668 12.0986 8.62305C11.542 9.27344 10.8477 9.59863 10.0156 9.59863C9.58203 9.59863 9.18066 9.5166 8.81152 9.35254C8.44824 9.18848 8.04102 8.90723 7.58984 8.50879C7.13867 8.10449 6.81348 7.85547 6.61426 7.76172C6.4209 7.66211 6.21289 7.6123 5.99023 7.6123C5.59766 7.6123 5.28711 7.76465 5.05859 8.06934C4.83594 8.37402 4.72461 8.77246 4.72461 9.26465H3.02832C3.02832 8.2627 3.29492 7.44238 3.82812 6.80371C4.36133 6.15918 5.06152 5.83691 5.92871 5.83691C6.3623 5.83691 6.77246 5.9248 7.15918 6.10059C7.55176 6.27051 7.95898 6.54883 8.38086 6.93555C8.80859 7.32227 9.11914 7.56543 9.3125 7.66504C9.51172 7.76465 9.72559 7.81445 9.9541 7.81445C10.3467 7.81445 10.666 7.65039 10.9121 7.32227C11.1582 6.99414 11.2812 6.58398 11.2812 6.0918H12.9336Z" fill="white" />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
