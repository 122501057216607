const settingsRoutes = [
  {
    path: '/settings/members',
    component: () => import('../views/Members.vue'),
    name: 'members',
  },
  {
    path: '/settings/subscriptions',
    component: () => import('../views/Subscriptions.vue'),
    name: 'subscriptions',
  },
  {
    path: '/settings/subscriptions/:schedulerId/unsubscribe/:unsubscribeKey',
    name: 'unsubscribe',
    component: () => import('../views/Unsubscribe.vue'),
    meta: {
      isPublic: true,
    },
  },
  {
    path: '/settings/profile',
    component: () => import('../views/Profile.vue'),
    name: 'profile',
  },
  {
    path: '/settings/preferences',
    component: () => import('../views/Preferences.vue'),
    name: 'preferences',
  },
];

export default settingsRoutes;
