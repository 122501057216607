<template>
  <svg width="28" height="20" viewBox="0 0 28 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="28" height="20" rx="2" fill="white" />
    <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="28" height="20">
      <rect width="28" height="20" rx="2" fill="white" />
    </mask>
    <g mask="url(#mask0)">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M0 5.33333H28V0H0V5.33333Z" fill="#DD172C" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M0 20H28V14.6666H0V20Z" fill="#DD172C" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M0 14.6667H28V5.33337H0V14.6667Z" fill="#FFD133" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M7.33325 9.33333H8.66659V10H7.33325V9.33333Z" fill="#FFEDB1" />
      <path d="M6.0591 9.36098C6.0429 9.16663 6.19627 8.99996 6.39128 8.99996H8.27555C8.47056 8.99996 8.62393 9.16663 8.60773 9.36097L8.43645 11.4163C8.39326 11.9346 7.95999 12.3333 7.4399 12.3333H7.22693C6.70684 12.3333 6.27357 11.9346 6.23038 11.4163L6.0591 9.36098Z" stroke="#A41517" stroke-width="0.666667" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M6 10H8.66667V10.6667H8L7.33333 12L6.66667 10.6667H6V10Z" fill="#A41517" />
      <rect x="4" y="8" width="1.33333" height="4.66667" rx="0.666667" fill="#A41517" />
      <rect x="9.33325" y="8" width="1.33333" height="4.66667" rx="0.666667" fill="#A41517" />
      <path d="M6 7.73329C6 7.14419 6.47756 6.66663 7.06667 6.66663H7.6C8.1891 6.66663 8.66667 7.14419 8.66667 7.73329C8.66667 7.88057 8.54728 7.99996 8.4 7.99996H6.26667C6.11939 7.99996 6 7.88057 6 7.73329Z" fill="#A41517" />
    </g>
  </svg>
</template>
