<template>
  <svg width="17" height="12" viewBox="0 0 17 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect y="10" width="17" height="2" fill="#151515" />
    <rect width="17" height="2" fill="#151515" />
    <rect x="10" y="12" width="12" height="2" transform="rotate(-90 10 12)" fill="#151515" />
    <rect x="5" y="12" width="12" height="2" transform="rotate(-90 5 12)" fill="#151515" />
    <rect x="15" y="12" width="12" height="2" transform="rotate(-90 15 12)" fill="#151515" />
    <rect y="12" width="12" height="2" transform="rotate(-90 0 12)" fill="#151515" />
    <rect y="5" width="17" height="2" fill="#151515" />
  </svg>
</template>
