import moment from 'moment';
import isObject from 'lodash/isObject';
import size from 'lodash/size';
import without from 'lodash/without';
import omit from 'lodash/omit';
import first from 'lodash/first';
import keys from 'lodash/keys';
import { allowedRangeKeys, urlDateFormat } from '@/components/DatePicker/constants';

export const rangeValidator = (value) => {
  const rangeKeys = Object.keys(value);
  return isObject(value) && size(value) > 0 && rangeKeys.every((key) => allowedRangeKeys.includes(key));
};

export const lastValidator = (range) => {
  const rangeKeys = Object.keys(range);
  const allowedKeys = without(allowedRangeKeys, 'from', 'to');
  return isObject(range) && size(range) > 0 && rangeKeys.every((key) => allowedKeys.includes(key));
};

export const toMoment = (date, format) => {
  if (date && !format && typeof date === 'string' && date.match(/\d\d\d\d\/\d\d\/\d\d/)) {
    return moment(date, 'YYYY/MM/DD');
  }
  if (date && !format && typeof date === 'string' && date.match(/\d\d\/\d\d\/\d\d\d\d/)) {
    return moment(date, 'MM/DD/YYYY');
  }
  if (date && !format && typeof date === 'string' && date.match(/\d\d\d\d-\d\d-\d\d/)) {
    return moment(date, 'YYYY-MM-DD');
  }
  return moment(date, format);
};

export const stringifyRange = (range, dateFormat = urlDateFormat) => {
  let from;
  let to;
  switch (true) {
    case Boolean(range.from && range.to):
      from = moment(range.from).format(dateFormat);
      to = moment(range.to).format(dateFormat);
      break;
    case Boolean(range.from):
      from = moment(range.from).format(dateFormat);
      break;
    case Boolean(range.last): {
      const rangeLast = omit(range, 'last');
      const rangeLastKey = first(keys(rangeLast));
      const rangeLastValue = range[rangeLastKey];
      from = `last_${rangeLastValue}_${rangeLastKey}`;
      break;
    }
  }
  return to ? { from, to } : { from };
};

export const parseRange = ({ from, to }) => {
  if (from) {
    switch (true) {
      case Boolean(from && to):
        return { from, to };
      case from.includes('last'): {
        const [, rangeLastValue, rangeLastKey] = (/last_(\d+)_(.*)/gm).exec(from);
        return { [rangeLastKey]: parseInt(rangeLastValue, 10), last: true };
      }
      default:
        return { from };
    }
  }
  return null;
};
