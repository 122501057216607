import qs from 'qs';
import last from 'lodash/last';
import { memoize } from '@/lib/helpers';
import { getJson, deleteJson, putJson, postJson } from '@/services/request';
import { API_URL, DATA_API_URL, REPORT_PATH, LIBRARY_PATH } from '@/constants/endpoints';

const abortRfmControllers = [];
const abortReportsControllers = [];

export async function getLandingReports(template, industry) {
  const urlQueryString = qs.stringify({ template, industry }, { addQueryPrefix: true });
  return getJson(`${API_URL}/industry.json${urlQueryString}`);
}

export const getReportTemplates = memoize(async (table, industry = '') => {
  const { templates } = await getJson(`${DATA_API_URL}/data/table/${table}/industry/${industry.replace('@', '')}`);
  return templates;
}, (table, industry) => `${table}_${industry}`);

export async function getReports(filter, type) {
  abortReportsControllers.push(new AbortController());
  const urlQueryString = qs.stringify({ filter, type }, { addQueryPrefix: true });
  return getJson(`${REPORT_PATH}${urlQueryString}`, {
    signal: last(abortReportsControllers).signal,
  });
}

export async function updatePermissions({ id, permissions, is, template }) {
  return putJson(`${REPORT_PATH}/${id}?template=${template}`, {
    permissions,
    is,
  });
}

export async function getReport(id, accept) {
  try {
    return await getJson(`${REPORT_PATH}/${id}${accept ? `?accept=${accept}` : ''}`);
  } catch (e) {
    if (accept) {
      return getReport(id);
    }
    throw e;
  }
}

export async function getCustomReportPreview(templateId) {
  return postJson(`${LIBRARY_PATH}/${templateId}?preview=true`);
}

export async function getCustomReport(templateId, data) {
  abortRfmControllers.push(new AbortController());
  return postJson(`${LIBRARY_PATH}/${templateId}`, data, {
    signal: last(abortRfmControllers).signal,
  });
}

export async function createReport(name, description, path, segments) {
  return postJson(`${REPORT_PATH}`, {
    name,
    description,
    path: path || window.location.pathname,
    segments,
  });
}

export async function updateReport(id, name, description, path, segments) {
  return putJson(`${REPORT_PATH}/${id}`, {
    name,
    description,
    path: path || window.location.pathname,
    segments,
  });
}

export async function deleteReport(id) {
  return deleteJson(`${REPORT_PATH}/${id}`);
}

export async function unsubscribe(reportId) {
  await deleteJson(`${REPORT_PATH}/${reportId}/unsubscribe`);
}

export const abortLatestRfrReport = () => {
  if (abortRfmControllers.length) {
    const abortController = abortRfmControllers.pop();
    abortController.abort();
  }
};

export const abortAllRfrReport = () => {
  while (abortRfmControllers.length) {
    abortLatestRfrReport();
  }
};

export const abortLatestReports = () => {
  if (abortReportsControllers.length) {
    const abortController = abortReportsControllers.pop();
    abortController.abort();
  }
};

export const abortAllReports = () => {
  while (abortReportsControllers.length) {
    abortLatestReports();
  }
};
