import isNumber from 'lodash/isNumber';
import { configure, defineRule } from 'vee-validate';
import { required, email, min, max, confirmed } from '@vee-validate/rules';
import i18next from '@/plugins/i18n';
import numeral from '@/plugins/numeral';

const $t = i18next.t.bind(i18next);

defineRule('email', email);

defineRule('required', required);

defineRule('minValue', (value, params) => {
  return numeral(value).value() > params[0];
});

defineRule('amountWithCents', (value) => (/^\d+[.,]?[\d]{0,2}$/).test(numeral(value).value()));

defineRule('numeric', (value) => isNumber(numeral(value).value()));

defineRule('min', min);

defineRule('max', max);

defineRule('confirmed', confirmed);

defineRule('customTableName', (value) => !(/[^0-9a-z_]/g).test(value));

configure({
  generateMessage: (context) => {
    switch (context.rule.name) {
      case 'email':
        return $t('login.error_email_invalid');
      case 'required':
        return $t('form.error_required');
      case 'minValue':
        return $t('form.error_min_value', { min: context.rule.params[0] });
      case 'amountWithCents':
        return $t('form.error_amount_with_cents');
      case 'numeric':
        return $t('form.error_numeric');
      case 'min':
        return $t('form.error_min', { length: context.rule.params[0] });
      case 'max':
        return $t('form.error_max', { length: context.rule.params[0] });
      case 'confirmed':
        return $t('form.error_confirmed');
      case 'customTableName':
        return $t('form.error_custom_table_name');
      default:
        return `The field ${context.field} is invalid`;
    }
  },
});
